import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const PageHeader = styled.h1`
	font-size: 35px;
	font-weight: 300;
	line-height: 1.2;
	color: white;
	padding: 40px 0;

	${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      padding: 20px 0;
    }
  `}
`;

export const ContentWrapper = styled.div`
	flex: 1 0 auto;
	overflow-y: auto;
	border-radius: 5px;
	background-color: rgba(0, 53, 69, 0.36);
	color: white;
	font-size: 16px;
	line-height: 1.2;

	& .infinite-scroll {
		padding: 33px 28px;
	}

	${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      & .infinite-scroll {
		    padding: 20px 15px;
		  }
    }
  `}
`;

export const ControlWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding: 30px 0;

	button {
		margin: 0 24px;
	}

	.MuiButton-outlined {
		border-color: white;
	}

	${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      flex-direction: column-reverse;
      padding: 20px 0 0;
      
      button {
				width: 100%;
				margin: 0 0 10px;
      }
    }
  `}
`;

export const ControlButton = styled(Button)`
	height: 50px;
	border-radius: 25px;
	text-transform: none;
	color: white;
	min-width: 180px;
	padding-right: 20px;
	padding-left: 20px;
`;
