import React from 'react';
import * as Style from './Login.module.scss';

import PlayInfo from '../../components/login/playInfo/PlayInfo';
import LoginForm from '../../components/login/loginForm/LoginForm';

export default () => {
	return (
		<div className={Style.loginContainer}>
			<div className={Style.loginWrapper}>
				<PlayInfo />
				<LoginForm />
			</div>
		</div>
	);
};
