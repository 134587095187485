import styled from 'styled-components';
import { Backdrop } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export const DialogBackdrop = styled(Backdrop)`
	background-color: #00b2e7;
	z-index: 100;

	& .page {
		padding: 64px 0 80px;
		height: 100%;
	}
`;
