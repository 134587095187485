import React, { useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import * as firebase from 'firebase/app';

import * as Style from '../Login/Login.module.scss';

import PlayInfo from '../../components/login/playInfo/PlayInfo';
import ResetPassword from '../../components/ResetPassword/ResetPassword';

export default () => {
	const { search } = useLocation();
	const history = useHistory();

	useEffect(() => {
		firebase.auth().signOut();
	}, []);

	const searchParams = new URLSearchParams(search);

	let setMode = false;
	let token;

	if (searchParams.get('setToken')) {
		setMode = true;
		token = searchParams.get('setToken');
	} else if (searchParams.get('resetToken')) {
		token = searchParams.get('resetToken');
	}

	return (
		token ? (
			<div className={Style.loginContainer}>
				<div className={Style.loginWrapper}>
					<PlayInfo />
					<ResetPassword
						setMode={setMode}
						token={token}
						history={history}
					/>
				</div>
			</div>
		) : (
			<Redirect to="/" />
		)
	);
};
