import React, { useState, useCallback, useRef } from 'react';
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
	return {
		contentWrapper: {
			width: '100%',
			'flex-direction': 'column',
			display: 'flex'
		},
		cropContainer: {
			position: 'relative',
			overflow: 'hidden',
			width: '100%',
			height: '300px'
		},
		controls: {
			padding: '20px 10px 0'
		},
		buttonWrapper: {
			justifyContent: 'center'
		},
		applyButton: {
			width: '120px'
		},
		cancelButton: {
			width: '120px',
			background: 'linear-gradient(to right, #e70000 -56%, #f7e104 144%)',
			margin: 0
		}
	};
});

export default ({
	cropShape = 'round',
	showGrid = false,
	imageSrc = '',
	handleApply = () => null,
	handleCancel = () => null
}) => {
	const classes = useStyles();
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState({});
	const onCropComplete = useCallback((croppedArea, croppedPixels) => {
		setCroppedAreaPixels(croppedPixels);
	}, []);
	const cropSize = useRef({
		width: 103,
		height: 103
	});
	const clearState = () => {
		setCrop({ x: 0, y: 0 });
		setCrop(1);
	};
	const handleApplyClick = () => {
		clearState();
		handleApply(croppedAreaPixels);
	};
	const handleCancelClick = () => {
		clearState();
		handleCancel();
	};

	return (
		<div className={classes.contentWrapper}>
			<div className={classes.cropContainer}>
				<Cropper
					image={imageSrc}
					crop={crop}
					zoom={zoom}
					aspect={1}
					onCropChange={setCrop}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
					cropShape={cropShape}
					showGrid={showGrid}
					cropSize={cropSize.current}
				/>
			</div>
			<div className={classes.controls}>
				<Slider
					value={zoom}
					min={1}
					max={3}
					step={0.1}
					aria-labelledby="Zoom"
					onChange={(e, zoomValue) => setZoom(zoomValue)}
					classes={{ container: 'slider' }}
				/>
			</div>
			<DialogActions className={classes.buttonWrapper}>
				<Button className={classes.applyButton} onClick={handleApplyClick}>
					Apply
				</Button>
				<Button className={classes.cancelButton} onClick={handleCancelClick}>
					Cancel
				</Button>
			</DialogActions>
		</div>
	);
};
