import React, { useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import * as firebase from 'firebase/app';
import * as Style from './LoginForm.module.scss';
import { emailRegEx } from '../../../constants/constants';

export default () => {
	// region constants
	const auth = firebase.auth();

	// endregion

	const [loading, setLoading] = useState(false);
	const emailRef = useRef();
	const passwordRef = useRef();
	const [emailHelperMessage, setEmailHelperMessage] = useState('');
	const [passwordHelperMessage, setPasswordHelperMessage] = useState('');
	// region handlers
	const validateInput = (email, password) => {
		const isValidEmail = emailRegEx.test(email);
		const isValidPassword = password.length >= 6;

		if (!isValidEmail) {
			if (!email) {
				setEmailHelperMessage('Field is required');
			} else {
				setEmailHelperMessage('Invalid email pattern! eg: example@sceel.io');
			}
		} else {
			setEmailHelperMessage('');
		}
		if (!isValidPassword) {
			if (!password) {
				setPasswordHelperMessage('Field is required');
			} else {
				setPasswordHelperMessage(
					'Password length must be more than 6 characters'
				);
			}
		} else {
			setPasswordHelperMessage('');
		}
		return isValidEmail && isValidPassword;
	};
	const loginHandler = e => {
		e.preventDefault();
		const emailValue = emailRef.current.value;
		const passwordValue = passwordRef.current.value;

		const isValid = validateInput(emailValue, passwordValue);

		if (isValid) {
			setLoading(true);
			try {
				auth
					.signInWithEmailAndPassword(emailValue, passwordValue)
					.then(() => {
						setLoading(false);
					})
					.catch(err => {
						const errorCode = err.code;
						const errorMessage = err.message;
						if (errorCode === 'auth/user-not-found') {
							setPasswordHelperMessage(
								'Invalid Email or Password, please try again!'
							);
						} else if (errorCode === 'auth/wrong-password') {
							setPasswordHelperMessage(
								'The Email and Password do not match, please try again!'
							);
						} else {
							setEmailHelperMessage(errorMessage);
						}
						setLoading(false);
					});
			} catch (exc) {
				setLoading(false);
			}
		}
	};
	// endregion

	return (
		<form className={Style.formWrapper}>
			<p className={Style.loginTitle}>Sign in</p>
			<div className={Style.inputWrapper}>
				<TextField
					error={emailHelperMessage || passwordHelperMessage}
					inputRef={emailRef}
					className={`${Style.inputText}`}
					id="outlined-email-error-helper-text"
					placeholder="Email address"
					type="email"
					autoComplete="email"
					variant="outlined"
					aria-describedby="email-error-text"
					InputProps={{
						'data-testid': 'email-input',
						style: {
							borderRadius: '35px',
							borderColor: '#919191'
						},
						startAdornment: (
							<InputAdornment position="start">
								<MailOutlineIcon style={{ color: '#00b2e7' }} />
							</InputAdornment>
						)
					}}
				/>
				{emailHelperMessage && (
					<FormHelperText
						data-testid="email-error-message"
						style={{ color: '#b00020', marginLeft: '15px' }}
					>
						{emailHelperMessage}
					</FormHelperText>
				)}
			</div>
			<div className={Style.inputWrapper}>
				<TextField
					error={passwordHelperMessage || emailHelperMessage}
					inputRef={passwordRef}
					className={Style.inputText}
					id="outlined-passowrd-error-helper-text"
					placeholder="Password"
					type="password"
					autoComplete="current-password"
					aria-describedby="password-error-text"
					variant="outlined"
					InputProps={{
						'data-testid': 'password-input',
						style: {
							borderRadius: '35px',
							borderColor: '#919191'
						},
						startAdornment: (
							<InputAdornment position="start">
								<VpnKeyIcon className={Style.keyIcon} />
							</InputAdornment>
						)
					}}
				/>
				{passwordHelperMessage && (
					<FormHelperText
						data-testid="password-error-message"
						style={{ color: '#b00020', marginLeft: '15px' }}
					>
						{passwordHelperMessage}
					</FormHelperText>
				)}
			</div>

			<div className={Style.buttonWrapper}>
				<Button
					data-testid="login-button"
					className={Style.button}
					variant="contained"
					disabled={loading}
					onClick={loginHandler}
				>
					Login
				</Button>
				{loading && (
					<CircularProgress
						data-testid="loading-button"
						size={24}
						className={Style.buttonProgress}
					/>
				)}
			</div>
		</form>
	);
};
