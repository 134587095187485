import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import * as Style from './FilterBar.module.scss';

const FilterBar = ({ handlefilter, mediaQ }) => {
	const [responded, setResponded] = useState('all');
	const handleFilter = filter => {
		handlefilter(filter);
		setResponded(filter);
	};
	return (
		<div className={Style.filterBarWrapper}>
			<h4 className={Style.title}>Responses</h4>
			<div className={`${Style.filters} ${mediaQ && Style.fullWidth}`}>
				<Button
					className={`${Style.filterButton} ${responded === 'all' &&
						Style.active}`}
					onClick={() => handleFilter('all')}
				>
					All
				</Button>
				<Button
					data-testid="responded-button"
					className={`${Style.filterButton} ${responded &&
						responded !== 'all' &&
						Style.active}`}
					onClick={() => handleFilter(true)}
				>
					Responded
				</Button>
				<Button
					data-testid="not-responded-button"
					className={`${Style.filterButton} ${!responded &&
						responded !== 'all' &&
						Style.active}`}
					onClick={() => handleFilter(false)}
				>
					Pending Response
				</Button>
			</div>
		</div>
	);
};

export default FilterBar;
