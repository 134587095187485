import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import QuestionResponse from './QuestionResponse';
import QuestionResponseFinalStep from './QuestionResponseFinalStep';
import { CardsContext } from '../../context/Context';
import useFetchQuestion from '../../hooks/useFetchQuestion';

export default props => {
	const { history } = props;
	const { data } = history.location;
	const { id } = useParams();
	const [question, isQuestionLoading] = useFetchQuestion({}, id, !data);
	const [sortedCards, setSortedCards] = useState([]);
	const questionData = data || question;
	const [cards, loadingCards] = useContext(CardsContext);
	// todo change activeStep state to apply active class to Stepper , 1 means first step is active
	const [activeStep, setActiveStep] = useState(1);
	const [atFinalStep, setFinalStep] = useState(false);
	return (
		<div>
			{atFinalStep ? (
				<QuestionResponseFinalStep
					history={history}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					setFinalStep={setFinalStep}
					cards={sortedCards}
					setSortedCards={setSortedCards}
					loadingCards={loadingCards}
					question={questionData}
					isQuestionLoading={isQuestionLoading}
				/>
			) : (
				<QuestionResponse
					history={history}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					setFinalStep={setFinalStep}
					cards={cards}
					sortedCards={sortedCards}
					setSortedCards={setSortedCards}
					loadingCards={loadingCards}
					question={questionData}
					isQuestionLoading={isQuestionLoading}
				/>
			)}
		</div>
	);
};
