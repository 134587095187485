import React, { useEffect } from 'react';
import * as firebase from 'firebase';

const AnonymousDecorator = ({ children }) => {
	useEffect(() => {
		firebase
			.auth()
			.signInAnonymously()
			.catch(error => {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log(`Anonimous error: ${errorCode} - ${errorMessage}`);
			});

		return () => {
			firebase.auth().signOut();
		};
	}, []);

	return <div>{children}</div>;
};

export default AnonymousDecorator;
