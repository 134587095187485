import React, { memo } from 'react';
import { CircularProgress } from '@material-ui/core';
import Moment from 'react-moment';
import * as Style from './AskedQuestion.module.scss';
import defaultAvatar from '../../../assets/images/userPlacholder.png';

const AskedQuestion = ({ data, question, isLoading, color, position }) => {
	const askedData = data || question;
	return (
		<div
			className={`${Style.questionWrapper} ${position === 'absolute' &&
				Style.absolutePosition}`}
		>
			{isLoading ? (
				<CircularProgress size={30} />
			) : (
				<>
					<section className={Style.questioner}>
						<img src={askedData.avatar || defaultAvatar} alt="" />
						<div className={Style.questionerDescription}>
							<p>
								{askedData &&
									`${askedData.createrFirstName} ${askedData.createrLastName}`}
							</p>
							<span>
								<Moment format="DD MMM YYYY">
									{askedData && askedData.date}
								</Moment>
							</span>
						</div>
					</section>
					<section
						className={`${Style.question} ${
							color === 'primary' ? Style.primary : Style.secondary
						}`}
					>
						<p className={Style.text}>{askedData && askedData.text}</p>
					</section>
				</>
			)}
		</div>
	);
};

export default memo(AskedQuestion);
