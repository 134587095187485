/* eslint-disable no-unused-expressions */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import Person from '@material-ui/icons/Person';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import debounce from 'lodash/debounce';

import './UserSearch.module.scss';
import useFetchUsers from '../../hooks/useFetchUsers';
import { getSortedByStringField } from '../../helpers/helpers';
import { GlobalContext } from '../../context/Context';
import ToggleButton from '../ToggleButton/ToggleButton';
import useToggle from '../../hooks/useToggle';
import {
	emailFieldError,
	emailRegEx,
	RECIPIENTS_TYPES
} from '../../constants/constants';
import {
	StyledFormControl,
	StyledInput,
	StyledLabel
} from '../ToggleButton/partials';

const useStyles = makeStyles({
	personIcon: {
		color: '#fff',
		marginLeft: '1rem'
	},
	adornment: {
		margin: '1rem',
		color: '#fff'
	}
});

const UserSearch = props => {
	const classes = useStyles();
	const [auth] = useContext(GlobalContext);
	const [inputValue, setInputValue] = useState('');
	const [users, isLoading] = useFetchUsers(auth?.subscriptionId);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [areAllSelectedHidden, setAreAllSelectedHidden] = useState(false);
	const [isSelectAllChecked, setIsAllSelectedChecked] = useState(false);
	const [open, setOpen] = useState(false);
	const [type, setType] = useToggle();
	const { setErrors, selectedUsers, setSelectedUsers } = props;
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;
	const isGuestSelected = type === RECIPIENTS_TYPES.GUEST;

	const handleSelectAll = () => {
		if (!isSelectAllChecked) {
			setIsAllSelectedChecked(true);
			setErrors(prevState => ({
				...prevState,
				selectionError: ''
			}));
			setSelectedUsers(users);
			setOpen(false);
		} else {
			setIsAllSelectedChecked(false);
			setSelectedUsers([]);
		}
	};

	const handleToggleClick = useCallback(btnType => {
		setAreAllSelectedHidden(false);
		setIsAllSelectedChecked(false);
		setInputValue('');
		setSelectedUsers([]);
		setErrors(prevState => ({
			...prevState,
			selectionError: ''
		}));

		setType(btnType);
	}, []);
	const updateSelectedAnonymousUser = debounce(email => {
		setSelectedUsers({
			isAnonymous: true,
			email
		});
		setErrors(prevState => ({
			...prevState,
			selectionError: !emailRegEx.test(email) ? emailFieldError : ''
		}));
	}, 800);
	const handleInputChane = useCallback(evt => {
		const val = evt?.target?.value;
		setInputValue(val);
		val && updateSelectedAnonymousUser(val);
	}, []);

	useEffect(() => {
		const filterReducer = (accumulator = [], user) => {
			if (
				(user.firstName || user.lastName) &&
				(user.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
					user.lastName.toLowerCase().includes(inputValue.toLowerCase()))
			) {
				accumulator.push(user);
			}
			return accumulator;
		};
		if (inputValue === '') {
			setFilteredUsers(users);
		} else {
			setFilteredUsers(users.reduce(filterReducer, []));
		}
	}, [users]);
	if (isLoading) {
		return <div>Loading.... Please wait</div>;
	}
	return (
		<form autoComplete="off">
			<ToggleButton currentType={type} handleClick={handleToggleClick} />
			{!isGuestSelected && (
				<Autocomplete
					limitTags={5}
					clearOnBlur
					clearOnEscape
					onOpen={() => setOpen(true)}
					onClose={() => {
						setOpen(false);
						setInputValue('');
					}}
					open={open}
					onChange={(e, selected) => {
						if (selected) {
							setErrors(prevState => ({
								...prevState,
								selectionError: ''
							}));
						}
						setSelectedUsers(selected);
						if (selected.length === users.length) {
							setAreAllSelectedHidden(true);
						} else if (selected.length < users.length) {
							setAreAllSelectedHidden(false);
							setIsAllSelectedChecked(false);
							// setInputValue('')
						}
					}}
					className="autocomplete_form"
					value={selectedUsers}
					loading={isLoading}
					multiple
					disableCloseOnSelect
					id="users-search"
					noOptionsText="No user found."
					options={getSortedByStringField(filteredUsers, 'firstName')}
					getOptionSelected={(option, value) => option.id === value.id}
					filterOptions={user => user}
					getOptionLabel={option => {
						const { firstName, lastName } = option;
						return (
							<span>
								{firstName} {lastName}
							</span>
						);
					}}
					renderOption={(option, { selected }) => {
						return (
							<>
								<Checkbox
									color="primary"
									icon={icon}
									checkedIcon={checkedIcon}
									checked={selected}
								/>
								{`${option.firstName} ${option.lastName} `}
							</>
						);
					}}
					renderInput={params => {
						const { inputProps } = params;
						const { value } = inputProps;
						return (
							<TextField
								{...params}
								variant="outlined"
								label="Recipients"
								placeholder="Search"
								onChange={() => {
									if (value) {
										setInputValue(value);
										setAreAllSelectedHidden(true);
									} else {
										setInputValue('');
										setAreAllSelectedHidden(false);
									}
								}}
								InputProps={{
									...params.InputProps,
									startAdornment: (
										<>
											<InputAdornment
												classes={{ root: classes.adornment }}
												position="start"
											>
												<Person classes={{ root: classes.personIcon }} />
												{!areAllSelectedHidden && (
													<div>
														<Checkbox
															checked={isSelectAllChecked}
															onChange={handleSelectAll}
															color="primary"
															icon={icon}
															checkedIcon={checkedIcon}
														/>
														<span>Select All</span>
													</div>
												)}
											</InputAdornment>
											{params.InputProps.startAdornment}
										</>
									)
								}}
							/>
						);
					}}
				/>
			)}
			{isGuestSelected && (
				<StyledFormControl hiddenLabel variant="outlined" fullWidth>
					<StyledLabel htmlFor="input-recipient">Recipient</StyledLabel>
					<StyledInput
						clearOnBlur
						clearOnEscape
						id="input-recipient"
						labelWidth={60}
						value={inputValue}
						onChange={handleInputChane}
						startAdornment={
							<InputAdornment className="person-icon" position="start">
								<Person classes={{ root: classes.personIcon }} />
							</InputAdornment>
						}
						endAdornment={
							<InputAdornment className="key-icon" position="end">
								<KeyboardIcon />
							</InputAdornment>
						}
					/>
				</StyledFormControl>
			)}
		</form>
	);
};

export default UserSearch;
