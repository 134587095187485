import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as Styles from './MyQuestions.module.scss';
import QuestionRow from '../../components/QuestionRow/QuestionRow';
import AlertComponent from '../../components/Alert/Alert';
import useFetch from '../../hooks/useFetch';
import { isBottom } from '../../helpers/helpers';
import { db } from '../../firebase';
import { GlobalContext } from '../../context/Context';

const useQuestions = (initialDataValue, initialPage, initialPageSize) => {
	const [page, setPage] = useState(initialPage);
	const [pageSize] = useState(initialPageSize);
	const [auth] = useContext(GlobalContext);

	const callback = async () => {
		const collectionRef = db.collection('questions');
		const limit = page * pageSize;
		const query = collectionRef
			.where('creater_id', '==', auth.uid)
			.where('subscription_id', '==', auth.subscriptionId)
			.orderBy('date', 'desc')
			.limit(limit);

		const collectIdsAndDocs = doc => {
			const { text, date, responded = [], recipients = [] } = doc.data();
			const respondedCount = Object.keys(responded).length;
			const recipientsCount = recipients.length || 1;

			return {
				id: doc.id,
				date: date.toDate(),
				text,
				recipientsCount,
				responded: respondedCount,
				notResponded: recipientsCount - respondedCount
			};
		};

		const snapshot = await query.get();
		return snapshot.docs.map(collectIdsAndDocs);
	};
	const [questions, isLoading, error, isError] = useFetch([], callback, [page]);

	return [questions, isLoading, error, isError, page, setPage];
};

const MyQuestions = ({ match }) => {
	// use setPage and currentPage to get needed page
	const [questions, isLoading, error, isError, page, setPage] = useQuestions(
		[],
		1,
		20
	);
	const [hideError, setHideError] = useState(false);
	const container = document.getElementsByClassName('infinite-scroll')[0];

	return (
		<div className="container">
			<h1 className="title text-center">What questions did I ask?</h1>
			<div className="scroll-container">
				{isError && !hideError && (
					<AlertComponent
						severityIndicator="error"
						message={error}
						setHideMessage={setHideError}
					/>
				)}
				{!isError && (
					<div
						onScroll={() => (isBottom(container) ? setPage(page + 1) : null)}
						className="questions infinite-scroll"
					>
						{isLoading && <div className="loader" />}
						{!isLoading && questions.length === 0 ? (
							<h1
								className={`text-center text-white font-weight-medium ${Styles.no_items_text}`}
							>
								no Question ....
							</h1>
						) : (
							questions.map(question => (
								<NavLink to={`${match.url}/${question.id}`} key={question.id}>
									<QuestionRow {...question} />
								</NavLink>
							))
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default MyQuestions;
