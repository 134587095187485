import { useEffect, useState } from 'react';

const useFetch = (initialDataValue, callback, dependencies = []) => {
	const [data, setData] = useState(initialDataValue);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		let isSubscribed = true;
		setIsError(false);
		setIsLoading(true);

		async function fetchData() {
			try {
				return await callback();
			} catch (er) {
				if (isSubscribed) {
					setIsError(true);
					setError(er.message);
				}
			}
			return [];
		}

		fetchData().then(documentsData => {
			if (isSubscribed) {
				setData(documentsData);
				setIsLoading(false);
			}
		});
		// clean up function
		return () => {
			isSubscribed = false;
		};
	}, dependencies);

	return [data, isLoading, error, isError];
};

export default useFetch;
