import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Paper } from '@material-ui/core';
import * as SecondStepStyle from '../../../containers/QuestionResponse/QuestionResponseFinalStep.module.scss';
import MotivatorCard from '../MotivatorCard/MotivatorCard';
import commentIcon from '../../../assets/images/positive_comment.svg';

const PositiveDropZone = ({
	classes,
	positiveCards,
	selectCard,
	selectedCardHighlight
}) => {
	return (
		<Droppable droppableId="positive-dropZone" direction="horizontal">
			{(provided, snapshot) => (
				<Paper
					square
					ref={provided.innerRef}
					{...provided.droppableProps}
					className={`${classes.paper} ${
						SecondStepStyle.positive_changes
					} ${snapshot.isDraggingOver && SecondStepStyle.isDraggingOver}`}
					elevation={0}
				>
					<span
						className={`${SecondStepStyle.lane_label} ${SecondStepStyle.positive_label}`}
					>
						Positive Change
					</span>
					{positiveCards.map((card, index) => (
						<Draggable
							draggableId={card.id}
							key={card.id}
							index={index}
							isDragDisabled={card.isDragDisabled}
						>
							{(dragProvided, dragSnapshot) =>
								card.imgs ? (
									<div
										className={`${SecondStepStyle.cardWrapper} ${SecondStepStyle.animateCard}`}
										{...dragProvided.draggableProps}
										{...dragProvided.dragHandleProps}
										ref={dragProvided.innerRef}
										onClick={() => selectCard(card)}
									>
										<div
											className={`${
												SecondStepStyle.cardComment
											} ${selectedCardHighlight &&
												selectedCardHighlight.id === card.id &&
												SecondStepStyle.positiveHighlighted}`}
										>
											{card.comment && <img src={commentIcon} alt="" />}
										</div>
										<MotivatorCard
											title={card.name}
											src={card.imgs.desktop}
											width={60}
											height={70}
											isDragging={dragSnapshot.isDragging}
										/>
									</div>
								) : (
									<div
										className={SecondStepStyle.dummyCard}
										{...dragProvided.draggableProps}
										{...dragProvided.dragHandleProps}
										ref={dragProvided.innerRef}
									/>
								)
							}
						</Draggable>
					))}
				</Paper>
			)}
		</Droppable>
	);
};

export default PositiveDropZone;
