import React, { useState } from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { makeStyles } from '@material-ui/core/styles';
import MotivatorCard from '../../QuestionResponse/MotivatorCard/MotivatorCard';
import * as Style from './ReviewCards.module.scss';
import mostImp from '../../../assets/images/mostImportant.svg';
import leastImp from '../../../assets/images/leastImportant.svg';

const useStyles = makeStyles(theme => ({
	effect: {
		borderRadius: '50px',
		height: '30px',
		width: '30px',
		padding: 3,
		position: 'absolute',
		top: '-15px',
		left: '-5px',
		zIndex: '1'
	},
	negative: {
		color: theme.palette.secondary.light,
		background: 'red'
	},
	positive: {
		color: theme.palette.secondary.light,
		background: theme.palette.secondary.main
	}
}));
const ReviewCards = ({ cards, selectCard, mobileMediaQuery }) => {
	const classes = useStyles();
	const [scaleCard, setScaleCard] = useState(null);
	const cardClickHandler = (card, index) => {
		if (card.impact !== 0) {
			selectCard(card);
			setScaleCard(index);
		}
	};

	return (
		<div className={Style.cardsContainer}>
			{cards &&
				cards.map((card, index) => {
					return (
						<div
							className={`${Style.cardWrapper} ${scaleCard === index &&
								Style.scale}`}
							key={card.id}
							onClick={() => cardClickHandler(card, index)}
						>
							{index === 0 && (
								<div className={`${Style.laneIndicator} ${Style.left}`}>
									<img src={leastImp} alt="" />
									<p>Least important</p>
								</div>
							)}
							{index === cards.length - 1 && (
								<div className={`${Style.laneIndicator} ${Style.right}`}>
									<img src={mostImp} alt="" />
									<p>Most important</p>
								</div>
							)}

							{!!card.impact &&
								(card.impact === 1 ? (
									<ArrowUpwardIcon
										className={`${classes.effect} ${classes.positive}`}
										size={28}
									/>
								) : (
									<ArrowDownwardIcon
										className={`${classes.effect} ${classes.negative}`}
										size={28}
									/>
								))}

							<MotivatorCard
								title={card.name}
								src={card.imgs.desktop}
								width={mobileMediaQuery ? 80 : 120}
								height={mobileMediaQuery ? 80 : 120}
							/>
						</div>
					);
				})}
		</div>
	);
};

export default ReviewCards;
