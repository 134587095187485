import axios from 'axios';
import { getAuthToken } from './authHelper';

const host = process.env.REACT_APP_API_HOST ?? '';

export const deleteUser = async id => {
	if (!id) {
		return null;
	}

	const idToken = await getAuthToken(true);

	if (!idToken) {
		return null;
	}
	return axios.delete(`${host}/api/users/${id}`, {
		headers: {
			Authorization: `Bearer ${idToken}`
		}
	});
};

export const createUser = async data => {
	if (!data || !Object.keys(data).length) {
		return null;
	}

	const idToken = await getAuthToken(true);

	if (!idToken) {
		return null;
	}
	return axios.post(`${host}/api/users`, data, {
		headers: {
			Authorization: `Bearer ${idToken}`
		}
	});
};

export const updateUser = async (id, data) => {
	if (!id || !data || !Object.keys(data).length) {
		return null;
	}

	const idToken = await getAuthToken(true);

	if (!idToken) {
		return null;
	}
	return axios.put(`${host}/api/users/${id}`, data, {
		headers: {
			Authorization: `Bearer ${idToken}`
		}
	});
};

export const resetPassword = data => {
	const { password, token } = data;

	if (!password || !token) {
		return null;
	}

	return axios.post(`${host}/reset_password`, {
		password,
		token
	});
};

export const resetPasswordRequest = async id => {
	if (!id) {
		return null;
	}

	const idToken = await getAuthToken(true);

	if (!idToken) {
		return null;
	}
	return axios.get(`${host}/api/users/${id}/reset`, {
		headers: {
			Authorization: `Bearer ${idToken}`
		}
	});
};
