import React, { useState, useCallback } from 'react';
import { Dialog, Grid } from '@material-ui/core';
import UserItem from '../UserItem/UserItem';
import useDeleteUser from '../../../hooks/useDeleteUser';
import * as SC from './partials';

const UserList = props => {
	const { query, users, openEditDialog } = props;

	const [removeUserId, setRemoveUserId] = useState(null);

	const closeDeleteDialog = useCallback(() => {
		setRemoveUserId(null);
	}, []);

	const [deleteUserRequest, inRemoving] = useDeleteUser({
		onSuccess: closeDeleteDialog
	});

	const openDeleteDialog = useCallback(id => {
		setRemoveUserId(id);
	}, []);

	const handleUserDelete = useCallback(() => {
		deleteUserRequest(removeUserId);
	}, [deleteUserRequest, removeUserId]);

	const filteredUsers = query
		? users.filter(user => {
				const { firstName, lastName } = user;
				const serchRow = `${firstName} ${lastName}`.toLowerCase();
				return serchRow.indexOf(query) !== -1;
		  })
		: users;

	const renderUsers = () =>
		filteredUsers.map(user => (
			<UserItem
				key={user.id}
				data={user}
				onEdit={openEditDialog}
				onDelete={openDeleteDialog}
			/>
		));

	return (
		<Grid container spacing={1}>
			{renderUsers()}

			<Dialog open={!!removeUserId} onClose={closeDeleteDialog}>
				<SC.DialogContent>
					<SC.DialogIcon>i</SC.DialogIcon>
					<span>Are you sure?</span>
					<p>
						You will delete the user from the system, this action can’t be
						reversed.
					</p>
					<p>Please confirm that you want to delete the user</p>
				</SC.DialogContent>
				<SC.DialogControls>
					<SC.CancelButton onClick={closeDeleteDialog} variant="outlined">
						Cancel
					</SC.CancelButton>
					<SC.AgreeButton
						onClick={handleUserDelete}
						variant="contained"
						disabled={inRemoving}
						autoFocus
					>
						Yes, delete the user
					</SC.AgreeButton>
				</SC.DialogControls>
			</Dialog>
		</Grid>
	);
};

export default UserList;
