import styled from 'styled-components';
import { Avatar, IconButton } from '@material-ui/core';

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 66px;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 0.17);
`;

export const InfoWrapper = styled.div`
	display: flex;
	flex-shrink: 1;
	overflow: hidden;
`;

export const Logo = styled(Avatar)`
	margin-left: 14px;
	margin-right: 20px;
`;
export const Info = styled.div`
	display: flex;
	flex-shrink: 1;
	flex-direction: column;
	font-family: Lato;
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: italic;
	line-height: 1.19;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;

	& > span:first-child {
		font-size: 18px;
		font-weight: 900;
		font-style: normal;
		white-space: nowrap;
	}
`;
export const Control = styled.div`
	display: flex;
`;

export const ControlButton = styled(IconButton)`
	border-radius: 50%;
	border: 2px solid ${props => props.customcolor || 'white'};
	width: 40px;
	height: 40px;
	color: ${props => props.customcolor || 'white'};
	margin-right: 12px;
	padding: 0;
`;
