import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import Respondents from '../../components/Question/Respondents/Respondents';
import * as Style from './Question.module.scss';
import defaultAvatar from '../../assets/images/userPlacholder.png';
import useFetchQuestion from '../../hooks/useFetchQuestion';
import AlertComponent from '../../components/Alert/Alert';
import useWindowSize from '../../hooks/useWindowSize';

const useButtonStyle = makeStyles(theme => ({
	root: {
		'&.Mui-disabled': {
			'& svg': {
				backgroundColor: theme.props.buttons.backgroundColor,
				color: theme.props.buttons.color,
				border: 'none'
			}
		}
	}
}));
const Question = ({ history }) => {
	const classes = useButtonStyle();
	const { height } = useWindowSize();
	const { id } = useParams();
	const [question, isLoading, error, isError] = useFetchQuestion({}, id);
	const [hideError, setHideError] = useState(false);
	const handleError = () => {
		setHideError(true);
	};
	const {
		createrFirstName,
		createrLastName,
		respondedCount,
		text,
		date,
		recipients = [],
		prevId,
		nextId,
		avatar
	} = question;
	const handleNavButton = direction => {
		if (direction === 'prev') history.push(`/questions/${prevId}`);
		else history.push(`/questions/${nextId}`);
	};
	const handlePrevButton = () => handleNavButton('prev');
	const handleNextButton = () => handleNavButton('next');
	const Scroll = {
		height: `${height - 200}px`,
		overflowY: 'scroll'
	};
	return (
		<>
			{isLoading ? (
				<div className="loader" />
			) : (
				<div className={Style.questionContainer} style={Scroll}>
					<section>
						<Button
							className={classes.root}
							disabled={!prevId}
							onClick={handlePrevButton}
						>
							<ArrowBackIcon />
						</Button>
						<p>Prev Question</p>
					</section>
					<section className="container">
						<div className={Style.questionWrapper}>
							<div className={Style.avatar}>
								<img src={avatar || defaultAvatar} alt="" />
								<p>
									{createrFirstName} {createrLastName}
								</p>
							</div>
							<div className={Style.question}>
								<div className={Style.questionLeft}>
									<p>{text}</p>
									<p>
										<Moment format="DD MMM YYYY">{date}</Moment>
									</p>
								</div>
								<div className={Style.questionRight}>
									<p>
										<span>{respondedCount}</span>/{recipients.length}
									</p>
									<p>Response</p>
								</div>
							</div>
						</div>
						<Respondents recipients={recipients} question={question} />
					</section>
					<section>
						<Button
							className={classes.root}
							disabled={!nextId}
							onClick={handleNextButton}
						>
							<ArrowForwardIcon />
						</Button>
						<p>Next Question</p>
					</section>
				</div>
			)}
			{isError && !hideError && (
				<AlertComponent
					severityIndicator="error"
					message={error}
					setHideMessage={handleError}
				/>
			)}
		</>
	);
};

export default Question;
