import styled from 'styled-components';
import {
	Button,
	InputLabel,
	FormControl,
	OutlinedInput,
	Typography
} from '@material-ui/core';

export const StyledContentContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2rem;
	margin: 0 1rem 1.5rem;

	.question-hint {
		padding-right: 0.5rem;
	}

	.recipients-type-btn {
		text-transform: lowercase;
		min-width: 9.2rem;
		margin: 0;

		&:first-of-type {
			margin-right: 0.5rem;
		}
	}
`;

export const StyledButton = styled(Button)`
	border: 1px solid #ededed;
	box-shadow: 0 13px 26px 0 rgba(0, 186, 231, 0.17);
	border-radius: 2rem;
	background: ${props => (props.isActive ? 'white' : 'none')};
	color: #00b2e7;

	&:hover {
		background: ${props =>
			props.isActive ? props.theme.props.buttons.backgroundColor : ''};
	}
`;

export const StyledTypography = styled(Typography)`
	color: ${props => props.color};
	font-family: Lato, Roboto, Arial, sans-serif;
	font-size: 14px;
	font-weight: 800;
	line-height: 17px;
`;

export const StyledFormControl = styled(FormControl)`
	.Mui-focused {
		color: #fff;

		fieldset {
			border-color: #04f795 !important;
		}
	}
`;

export const StyledLabel = styled(InputLabel)`
	color: #fff;
`;

export const StyledInput = styled(OutlinedInput)`
	border-radius: 2rem;
	padding-left: 9px;
	padding-right: 9px;
	color: #fff;

	.person-icon,
	.key-icon {
		margin: 1rem;
		color: #fff;
	}

	fieldset {
		border: 1px solid #fff;
		outline-color: #fff;

		&:hover {
			border-color: #04f795;
		}
	}

	&&:hover {
		fieldset {
			border-color: #04f795;
		}
	}
`;
