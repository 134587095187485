import React from 'react';
import { Grid } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import * as SecondStepStyle from '../../../containers/QuestionResponse/QuestionResponseFinalStep.module.scss';
import PositiveDropZone from '../PositiveDropZone/PositiveDropZone';
import NeutralDropZone from '../NeutralDropZone/NeutralDropZone';
import NegativeDropZone from '../NegativeDropZone/NegativeDropZone';
import QuestionControl from '../QuestionControl/QuestionControl';

const CardsGrid = ({
	classes,
	handleDragEnd,
	handleDragStart,
	disableNeutralLaneDrop,
	getPositiveCards,
	handleCardSelection,
	selectedCardHighlight,
	getNeutralCards,
	getNegativeCards,
	handleGoingBack,
	handleSubmission,
	loadSubmit
}) => {
	return (
		<Grid
			classes={{ root: classes.grid }}
			container
			direction="column"
			justify="center"
			alignItems="center"
			item
			xs={12}
		>
			<DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
				<Droppable
					droppableId="parent-dropZone"
					direction="horizontal"
					isDropDisabled={disableNeutralLaneDrop}
				>
					{provided => (
						<div
							className={SecondStepStyle.dragDropContainer}
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							<PositiveDropZone
								classes={classes}
								positiveCards={getPositiveCards}
								selectCard={card => handleCardSelection(card)}
								selectedCardHighlight={selectedCardHighlight}
							/>
							<NeutralDropZone
								classes={classes}
								neutralCards={getNeutralCards}
							/>
							<NegativeDropZone
								classes={classes}
								negativeCards={getNegativeCards}
								selectCard={card => handleCardSelection(card)}
								selectedCardHighlight={selectedCardHighlight}
							/>
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<QuestionControl
				handleGoingBack={handleGoingBack}
				handleSubmission={handleSubmission}
				classes={classes}
				loadSubmit={loadSubmit}
			/>
		</Grid>
	);
};

export default CardsGrid;
