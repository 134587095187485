import { useState, useCallback } from 'react';
import { createUser, updateUser } from '../helpers/api';

const EDITABLE_VALUES = ['role', 'avatar'];

const prepareChangedValues = (
	newData = {},
	initData = {},
	editVlueList = []
) => {
	const preparedData = {};

	editVlueList.forEach(key => {
		const newValue = newData[key];
		const initValue = initData[key];

		if (typeof newValue !== 'undefined' && newValue !== initValue) {
			preparedData[key] = newValue;
		}
	});

	return preparedData;
};

export default (initData, opts = {}) => {
	const [inLoading, setInLoading] = useState(false);
	const [error, setError] = useState(null);

	const userId = initData?.id;

	const setUserRequest = useCallback(async data => {
		if (data && Object.keys(data).length) {
			setInLoading(true);

			try {
				if (userId) {
					const preparedData = prepareChangedValues(
						data,
						initData,
						EDITABLE_VALUES
					);
					await updateUser(userId, preparedData);
				} else {
					await createUser(data);
				}
				if (typeof opts.onSuccess === 'function') {
					opts.onSuccess();
				}
			} catch (err) {
				setError(err);
			} finally {
				setInLoading(false);
			}
		}
	}, []);

	return [setUserRequest, inLoading, error];
};
