import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import * as Style from './PlayInfo.module.scss';
import Logo from '../../../assets/images/logo.svg';

export default () => {
	const howToPlayRef = useRef();
	const [toggleScroll, setToggleScroll] = useState(false);
	const movingMotivatorRef = useRef();
	const scrollHandler = () => {
		howToPlayRef.current.style.opacity = !toggleScroll ? 1 : 0;
		movingMotivatorRef.current.style.opacity = toggleScroll ? 1 : 0;
		howToPlayRef.current.style.zIndex = !toggleScroll ? 1 : 0;
		movingMotivatorRef.current.style.zIndex = toggleScroll ? 1 : 0;
		setToggleScroll(!toggleScroll);
	};
	return (
		<div color="primary" className={Style.playInfo}>
			<section ref={movingMotivatorRef} className={Style.movingMotivator}>
				<img className={Style.logo} src={Logo} alt="" />
				<h2>Moving Motivators</h2>
				<p>Decisions at a glance!</p>
				<p>
					One of the easiest and definitely most fun ways to delve into both
					your intrinsic motivations (more on that below) is to play Moving
					Motivators!
				</p>
				<Button
					className={Style.moreButton}
					variant="contained"
					onClick={scrollHandler}
				>
					More Info
				</Button>
			</section>
			<section ref={howToPlayRef} className={Style.howToPlay}>
				<h1>How To Play</h1>
				<ul>
					<li>
						<Filter1Icon />
						Define which motivators are important to you. Place the cards in
						order from left(least important) to right (most important).
					</li>
					<li>
						<Filter2Icon />
						For example: if you got asked a question regarding learning a new
						skill and making all new friends, how does that affect what
						motivates you? it'll most likely increase some motivators and
						decrease others.Move the cards up for a positive change and down for
						a negative one and then look at whether you have more cards up or
						down.You can then submit the response.
					</li>
					<li>
						<Filter3Icon />
						The facilitator posing the question will check the responses and
						this is a great way to make decisions
					</li>
				</ul>
				<Button className={Style.scrollUpButton} onClick={scrollHandler}>
					<ExpandLessIcon />
				</Button>
			</section>
		</div>
	);
};
