import React, { useState } from 'react';
import { Dialog, IconButton, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import * as Style from './Hint.module.scss';

const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: 'auto',
		top: '5rem !important',
		width: '30rem',
		height: '17rem'
	},
	container: {
		backgroundColor: `${theme.props.backgrounds.semiOpaque}`,
		boxShadow: `0 13px 16px 0 ${theme.props.backgrounds.boxShadowColor}`,
		borderRadius: '0.625rem',
		height: '25rem',
		padding: '1rem'
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		color: `${theme.palette.secondary.light}`,
		justifyContent: 'center',
		backgroundColor: 'transparent',
		lineHeight: '2rem',
		height: '100%',
		padding: '0',
		margin: '0',
		width: '100%'
	},
	closeButton: {
		color: `${theme.props.icons.close}`,
		height: '48px',
		width: '48px',
		position: 'absolute',
		right: '0.5rem',
		padding: '0',
		margin: '0',
		bottom: '79%'
	},
	customHeading: {
		fontFamily: 'Lato, Arial, sans-serif',
		margin: '0 auto',
		marginTop: '1rem',
		fontSize: '1.1rem',
		fontWeight: 'bold',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.88',
		letterSpacing: 'normal',
		textAlign: 'center',
		color: `${theme.palette.secondary.light}`
	},
	customHeadingTwo: {
		fontFamily: 'Lato, Arial, sans-serif',
		fontSize: '0.9rem',
		fontWeight: '300',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: '2.13',
		letterSpacing: 'normal',
		textAlign: 'center',
		color: `${theme.palette.secondary.light}`,
		margin: '0 auto'
	}
}));

export default ({ HintTitle, HintSubtitle, children }) => {
	const [open, setOpen] = useState(true);
	const [checked, setChecked] = useState(false);
	const classes = useStyles();

	const handleChange = event => {
		setChecked(event.target.checked);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			classes={{
				root: classes.root,
				container: classes.container,
				paper: classes.paper
			}}
			onClose={() => setOpen(false)}
			aria-labelledby="hint-title"
			open={open}
		>
			{children}
			<IconButton
				aria-label="close"
				className={classes.closeButton}
				onClick={handleClose}
			>
				<CloseIcon />
			</IconButton>
			<h1 className={classes.customHeading}>{HintTitle}</h1>
			<h3 className={classes.customHeadingTwo}>{HintSubtitle}</h3>
			<div className={`${Style.flexContainer} ${Style.clearTop}`}>
				<Switch
					color="primary"
					checked={checked}
					onChange={handleChange}
					name="checked"
					inputProps={{ 'aria-label': 'primary checkbox' }}
				/>
				<p>Do not show this again</p>
			</div>
		</Dialog>
	);
};
