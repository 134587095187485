/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import HdprContent from '../GdprContent/GdprContent';
import * as SC from './partials';

const HdprDialog = props => {
	const { open, ...rest } = props;

	useEffect(() => {
		const $container = document.querySelector('#root > div');

		if (open) {
			$container?.classList.add('full-height-container');
		} else {
			$container?.classList.remove('full-height-container');
		}
	}, [open]);

	return (
		<SC.DialogBackdrop open={open}>
			<div className="page">
				<div className="page-wrapper">
					<HdprContent {...rest} />
				</div>
			</div>
		</SC.DialogBackdrop>
	);
};

export default HdprDialog;
