import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AskedQuestion from '../../components/QuestionResponse/AskedQuestion/AskedQuestion';
import * as Style from './QuestionResponse.module.scss';
import * as HintStyle from '../../components/Hint/Hint.module.scss';
import MotivatorCard from '../../components/QuestionResponse/MotivatorCard/MotivatorCard';
import CardsStepper from '../../components/QuestionResponse/Stepper/Stepper';
import CardSlot from '../../components/QuestionResponse/CardSlot/CardSlot';
import StyledPlaceholder from './QuestionResponse.style';
import StyledBox from '../../components/QuestionResponse/CardSlot/CardSlot.style';
import Alert from '../../components/Alert/Alert';
import Hint from '../../components/Hint/Hint';
import leastImp from '../../assets/images/leastImportant.svg';
import mostImp from '../../assets/images/mostImportant.svg';

const useStyles = makeStyles({
	alertContainer: {
		margin: '1.5rem'
	}
});

const QuestionResponse = ({
	history,
	activeStep,
	setActiveStep,
	setFinalStep,
	cards,
	sortedCards,
	setSortedCards,
	loadingCards,
	question,
	isQuestionLoading
}) => {
	const { data } = history.location;
	const classes = useStyles();
	const theme = useTheme();
	const mobileMediaQuery = useMediaQuery('(min-width:1030px)');
	const [hasDragStarted, setHasDragStarted] = useState(false);
	const [isAlertDisplayed, setIsAlertDisplayed] = useState(false);
	const [isNextDisabled, setNextDisabled] = useState(true);
	const alertMessage = `Cards you sort at the top are the most important.`;
	const [state, setState] = useState({
		cards: [],
		slots: {}
	});
	const allSlots = Object.keys(state.slots);

	useEffect(() => {
		if (!mobileMediaQuery) {
			setSortedCards(state.cards);
		}
	}, [mobileMediaQuery, setSortedCards, state.cards]);

	useEffect(() => {
		if (sortedCards.length) {
			setNextDisabled(false);
		}
	}, [sortedCards]);

	useEffect(() => {
		if (!state.cards.length && !loadingCards) {
			const allSortedCards = [];
			allSlots.forEach(slot => {
				allSortedCards.push(state.slots[slot].associatedCard[0]);
			});
			setSortedCards(allSortedCards);
		}
	}, [state.cards.length, setSortedCards, state.slots, loadingCards]);

	useEffect(() => {
		if (!loadingCards) {
			setState({
				slots: (() => {
					return cards.reduce((acc, card, index) => {
						return Object.assign(acc, {
							[`slot_${index + 1}`]: {
								id: `slot_${index + 1}`,
								cardId: null,
								associatedCard: {}
							}
						});
					}, {});
				})(),
				cards
			});
		}
	}, [loadingCards, cards]);

	const onDragStart = () => {
		setHasDragStarted(true);
	};

	const onDragEnd = result => {
		setHasDragStarted(false);
		const { destination, source, draggableId } = result;
		if (!destination) {
			return;
		}
		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}

		const start = source.droppableId;
		const end = destination.droppableId;

		if (start === end) {
			const newCards = [...state.cards];
			newCards.splice(source.index, 1);
			newCards.splice(destination.index, 0, {
				...state.cards[source.index],
				id: draggableId
			});
			setState(prevState => ({
				...prevState,
				cards: newCards
			}));
		}
		if (start !== end && destination.droppableId !== 'cards_section') {
			const startCards = [...state.cards];
			const destinationSlot = state.slots[destination.droppableId];
			const associatedCard = startCards.splice(source.index, 1);
			if (destinationSlot.cardId) {
				startCards.splice(source.index, 0, {
					...destinationSlot.associatedCard[0],
					id: destinationSlot.cardId
				});
			}
			const newSlots = {
				...state.slots,
				[destination.droppableId]: {
					...destinationSlot,
					cardId: draggableId,
					associatedCard
				}
			};

			setState(() => ({
				cards: startCards,
				slots: newSlots
			}));
		}
		if (
			destination.droppableId.includes('slot') &&
			source.droppableId.includes('slot')
		) {
			const sourceCard = state.slots[source.droppableId];
			const newCard = state.slots[destination.droppableId];
			const newSlots = {
				...state.slots,
				[source.droppableId]: {
					...sourceCard,
					cardId: newCard.cardId,
					associatedCard: { ...newCard.associatedCard }
				},
				[destination.droppableId]: {
					...newCard,
					cardId: sourceCard.cardId,
					associatedCard: { ...sourceCard.associatedCard }
				}
			};
			setState({
				cards: state.cards,
				slots: newSlots
			});
		}
	};

	const cardToRender = cardId => {
		return cards.find(card => card.id === cardId);
	};

	const handleCancel = () => {
		history.push('/responses');
	};

	const handleNextStep = e => {
		e.preventDefault();
		setFinalStep(true);
		setActiveStep(2);
	};

	return (
		<DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
			<div className={Style.questionResponseContainer}>
				{!loadingCards && (
					<AskedQuestion
						data={data}
						question={question}
						isLoading={isQuestionLoading}
						color="primary"
						position={mobileMediaQuery ? 'absolute' : 'static'}
					/>
				)}
				<CardsStepper activeStep={activeStep} />
				{loadingCards ? (
					<div className={Style.loadingContainer}>
						<CircularProgress
							data-testid="loading-button"
							size={30}
							className={Style.buttonProgress}
						/>
					</div>
				) : (
					mobileMediaQuery && (
						<>
							<Hint
								HintTitle="Sort the motivators from Least Important to Most Important."
								HintSubtitle="Drag and drop the cards in the corresponding order of importance to you."
							>
								<div className={HintStyle.flexContainer}>
									<FontAwesomeIcon
										className={HintStyle.arrow_indicators__distance}
										size="4x"
										color={theme.props.icons.sortDown}
										icon={['fas', 'sort-amount-down-alt']}
									/>
								</div>
							</Hint>
							<section className={Style.navigationCardsWrapper}>
								<Button
									onClick={handleCancel}
									className={`${Style.navigationButtons} ${Style.cancelButton}`}
								>
									Cancel
								</Button>
								<div className={Style.cardContainer}>
									{allSlots &&
										allSlots.map((slot, index) => {
											const currentSlot = state.slots[slot];
											const { cardId } = currentSlot;
											const chosenCard = cardToRender(cardId);
											return (
												<div className={Style.slotContainer}>
													{index === 0 && (
														<div
															className={`${Style.laneIndicator} ${Style.left}`}
														>
															<img src={leastImp} alt="" />
															<p>Least important</p>
														</div>
													)}
													{index === allSlots.length - 1 && (
														<div
															className={`${Style.laneIndicator} ${Style.right}`}
														>
															<img src={mostImp} alt="" />
															<p>Most important</p>
														</div>
													)}
													<CardSlot
														slot={currentSlot}
														cardId={cardId}
														key={slot}
													>
														{chosenCard && (
															<Draggable
																draggableId={cardId.toString()}
																index={index}
																key={cardId}
															>
																{(provided, snapshot) => (
																	<Box
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		ref={provided.innerRef}
																		isDragging={snapshot.isDragging}
																	>
																		<MotivatorCard
																			title={chosenCard.name}
																			src={chosenCard.imgs.desktop}
																			width={100}
																			height={100}
																		/>
																	</Box>
																)}
															</Draggable>
														)}
														{!chosenCard && (
															<StyledPlaceholder
																hasDragStarted={hasDragStarted}
															>
																{index + 1}
															</StyledPlaceholder>
														)}
													</CardSlot>
												</div>
											);
										})}
								</div>
								<Button
									disabled={isNextDisabled}
									onClick={handleNextStep}
									className={Style.navigationButtons}
								>
									Next
								</Button>
							</section>
							<Droppable direction="horizontal" droppableId="cards_section">
								{provided => (
									<Box
										className={Style.draggableCardsSection}
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{state.cards &&
											state.cards.map((card, index) => {
												return (
													<Draggable
														draggableId={card.id.toString()}
														index={index}
														key={card.id}
													>
														{dragProvided => (
															<Box
																{...dragProvided.draggableProps}
																{...dragProvided.dragHandleProps}
																ref={dragProvided.innerRef}
															>
																<MotivatorCard
																	title={card.name}
																	src={card.imgs.desktop}
																	width={100}
																	height={100}
																/>
															</Box>
														)}
													</Draggable>
												);
											})}
										{provided.placeholder}
									</Box>
								)}
							</Droppable>
						</>
					)
				)}
				{!mobileMediaQuery && (
					<section className={Style.verticalCardWrapperContainer}>
						<Hint
							HintTitle="Sort the motivators from Least Important to Most Important."
							HintSubtitle="Drag and drop the cards in the corresponding order of importance to you."
						>
							<div className={HintStyle.flexContainer}>
								<FontAwesomeIcon
									className={HintStyle.arrow_indicators__distance}
									size="4x"
									color={theme.props.icons.sortDown}
									icon={['fas', 'sort-amount-down-alt']}
								/>
							</div>
						</Hint>
						)
						{!isAlertDisplayed && (
							<Alert
								classes={{ root: classes.alertContainer }}
								variant="filled"
								elevation={6}
								message={alertMessage}
								setHideMessage={setIsAlertDisplayed}
								severityIndicator="info"
							/>
						)}
						<Droppable droppableId="cards_section_vertical">
							{(provided, snapshot) => (
								<StyledBox
									className={Style.verticalCardWrapper}
									{...provided.droppableProps}
									ref={provided.innerRef}
									isDraggingOver={snapshot.isDraggingOver}
								>
									{state.cards.map((card, index) => {
										return (
											<Draggable
												draggableId={card.id.toString()}
												index={index}
												key={card.id}
											>
												{(draggableProvided, draggableSnapshot) => (
													<Box
														{...draggableProvided.draggableProps}
														{...draggableProvided.dragHandleProps}
														ref={draggableProvided.innerRef}
														isDragging={draggableSnapshot.isDragging}
														className={Style.cardContainer}
													>
														<MotivatorCard
															title={card.name}
															src={card.imgs.desktop}
															width={100}
															height={100}
														/>
													</Box>
												)}
											</Draggable>
										);
									})}
								</StyledBox>
							)}
						</Droppable>
						<Box className={Style.buttonsContainer}>
							<Button
								onClick={handleNextStep}
								className={Style.navigationButtons}
							>
								Next
							</Button>
							<Button
								onClick={handleCancel}
								className={`${Style.navigationButtons} ${Style.cancelButton}`}
							>
								Cancel
							</Button>
						</Box>
					</section>
				)}
			</div>
		</DragDropContext>
	);
};

export default QuestionResponse;
