/* eslint-disable prefer-const,camelcase,no-restricted-syntax,no-await-in-loop */
import { useContext, useEffect, useState } from 'react';
import * as firebase from 'firebase';
import { GlobalContext } from '../context/Context';
import { dateBaseOrder } from '../helpers/helpers';
import answersQueryHelper from '../helpers/answersQeryHelper';

const useFetchQuestion = (initialValue, id, canFetch = true) => {
	const [didCancel, setDidCancel] = useState(false);
	const [data, setData] = useState(initialValue);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [auth] = useContext(GlobalContext);

	const db = firebase.firestore();

	const nextQuery = db
		.collection('questions')
		.where('creater_id', '==', auth.uid)
		.orderBy('date', 'desc');
	const prevQuery = db
		.collection('questions')
		.where('creater_id', '==', auth.uid)
		.orderBy('date', 'asc');

	const fetchData = async () => {
		const respondedUsers = [];
		const pendingToResonseUsers = [];
		const errorsData = [];

		let { avatar } = auth;

		setIsError(false);
		setIsLoading(true);
		try {
			const documentSnapshot = await db
				.collection('questions')
				.doc(id)
				.get();

			const prevDocs = await prevQuery.startAfter(documentSnapshot).get();
			const prevDoc = prevDocs.docs && prevDocs.docs[0];
			const nextDocs = await nextQuery.startAfter(documentSnapshot).get();
			const nextDoc = nextDocs.docs && nextDocs.docs[0];

			const {
				text,
				date,
				responded,
				recipients = [],
				creater_first_name,
				creater_last_name,
				creater_id,
				...elseData
			} = documentSnapshot.data();

			const isAnonymous = !recipients.length;
			let recipientsData;
			const actualRecipients = isAnonymous ? [null] : recipients;

			for (const recipientId of actualRecipients) {
				try {
					let resultObject;
					if (isAnonymous) {
						const [anonymousRecipientId] = Object.keys(responded);

						resultObject = {
							id: anonymousRecipientId,
							name: 'Anonymous User',
							responded: !!Object.keys(responded).length
						};
					} else {
						const recipientSnapshot = await db
							.collection('users')
							.doc(recipientId)
							.get();

						const {
							first_name,
							last_name,
							...elseRecipientData
						} = recipientSnapshot.data();

						resultObject = {
							id: recipientId,
							name: `${first_name} ${last_name}`,
							responded: !!responded[recipientId],
							...elseRecipientData
						};
					}

					if (resultObject.responded) {
						try {
							const answerSnapshot = await answersQueryHelper(
								id,
								isAnonymous
									? responded[resultObject.id]
									: responded[recipientId]
							);

							if (answerSnapshot) {
								const { date: answerDate } = answerSnapshot.data();

								if (answerDate) {
									resultObject.date = answerDate.toDate();
								}
							}
						} catch (exc) {
							// eslint-disable-next-line no-console
							console.error(exc);
						}

						respondedUsers.push(resultObject);
					} else {
						pendingToResonseUsers.push(resultObject);
					}
				} catch (err) {
					errorsData.push({ name: err.message });
				}
			}

			respondedUsers.sort(dateBaseOrder);
			pendingToResonseUsers.sort(dateBaseOrder);

			recipientsData = respondedUsers
				.concat(pendingToResonseUsers)
				.concat(errorsData);

			if (auth.uid !== creater_id) {
				const creator = await db
					.collection('users')
					.doc(creater_id)
					.get();
				avatar = creator && creator.data().avatar;
			}

			const resultData = {
				id,
				date: date.toDate(),
				text,
				recipients: recipientsData,
				respondedCount: Object.keys(responded).length,
				responded,
				createrFirstName: creater_first_name,
				createrLastName: creater_last_name,
				creatorId: creater_id,
				avatar,
				prevId: prevDoc && prevDoc.id,
				nextId: nextDoc && nextDoc.id,
				...elseData
			};

			if (!didCancel) {
				setData(resultData);
			}
		} catch (er) {
			if (!didCancel) {
				setIsError(true);
				setError(er.message);
			}
		}
		setIsLoading(false);
	};

	// TODO we need to convert this useFetch to factory pattern with type to determine which collection will be retrieved
	useEffect(() => {
		setDidCancel(false);
		if (canFetch) {
			fetchData();
		}

		return () => {
			setDidCancel(true);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return [data, isLoading, error, isError];
};

export default useFetchQuestion;
