import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
	root: {
		width: props => props.width,
		height: props => props.height,
		transform: props => (props.isDragging ? 'scale(1.3)' : 'scale(1)'),
		transition: 'transform 0.3s ease-in-out',
		margin: '0 0.3rem',
		marginBottom: '1rem'
	},
	media: {
		height: '100%'
	}
});
const MotivatorCard = ({
	title,
	width,
	height,
	src,
	isDragging,
	isDraggingDone
}) => {
	const classes = useStyles({ width, height, isDragging });
	return (
		<Card elevation={0} className={classes.root}>
			<CardMedia className={classes.media} image={src} title={title} />
		</Card>
	);
};

export default MotivatorCard;
