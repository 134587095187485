import React from 'react';
import { Modal, Fade, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import * as Style from './GameInfoModal.module.scss';

const useStyles = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		marginRight: '10px'
	},
	modalRoot: {
		display: 'flex',
		margin: '1rem auto',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modalHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	paper: {
		display: 'flex',
		padding: '2rem',
		flexDirection: 'column',
		margin: '1rem',
		width: '50%',
		background: theme.props.backgrounds.primaryThemeBackground,
		color: '#222',
		border: '2px solid #000',
		outline: 'none',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			margin: '1.2rem',
			height: '100%',
			overflow: 'auto',
			fontSize: '0.8rem'
		}
	},
	infoIcon: {
		padding: '0',
		marginRight: '4px',
		color: 'white',
		'font-family': 'Montserrat',
		'font-size': '12px',
		'font-weight': '600',
		'font-stretch': 'normal',
		'font-style': 'normal',
		'line-height': '1.25',
		'letter-spacing': 'normal',
		'text-align': 'center'
	}
}));

export default () => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	// const mobileMediaQuery = useMediaQuery('(min-width:768px)');
	// const theme = useTheme();

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<div className={classes.wrapper}>
			<div>
				<IconButton
					onClick={handleOpen}
					className={classes.infoIcon}
					aria-label="info"
					size="medium"
				>
					<HelpIcon fontSize="large" />
				</IconButton>
				Help
			</div>
			<Modal
				className={classes.modalRoot}
				aria-labelledby="game-details"
				aria-describedby="how to play the moving motivators game"
				open={open}
				disableAutoFocus
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={open}>
					<div className={classes.paper}>
						<Box className={classes.modalHeader} id="modal-header">
							<h3 id="modal-title">How to Play</h3>
							<IconButton
								classes={{ root: classes.closeIcon }}
								onClick={handleClose}
							>
								<CloseIcon />
							</IconButton>
						</Box>

						<section className={Style.gameDetails}>
							<ol>
								<li>
									Define which motivators are important to you. Place the cards
									in order from left (least important) to right (most
									important).
								</li>
								<li>
									For example: if you got asked a question regarding learning a
									new skill and making all new friends, how does that affect
									what motivates you? it'll most likely increase some motivators
									and decrease others. Move the cards up for a positive change
									and down for a negative one and then look at whether you have
									more cards up or down. You can then submit the response.
								</li>
								<li>
									The facilitator posing the question will check the responses
									and this is a great way to make decisions.
								</li>
								<li>
									For example: if you got asked a question regarding learning a
									new skill and making all new friends, how does that affect
									what motivates you? it'll most likely increase some motivators
									and decrease others. Move the cards up for a positive change
									and down for a negative one and then look at whether you have
									more cards up or down. You can then submit the response.
								</li>
								<li>
									For example: if you got asked a question regarding learning a
									new skill and making all new friends, how does that affect
									what motivates you? it'll most likely increase some motivators
									and decrease others. Move the cards up for a positive change
									and down for a negative one and then look at whether you have
									more cards up or down. You can then submit the response.
								</li>
							</ol>
						</section>
					</div>
				</Fade>
			</Modal>
		</div>
	);
};
