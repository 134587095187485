import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as SC from './partials';

const UserItem = props => {
	const {
		data,
		data: { id, firstName, lastName, email, avatar } = {},
		onEdit,
		onDelete
	} = props;
	const handleEdit = () => {
		onEdit(data);
	};

	const onUserDelete = useCallback(() => {
		onDelete(id);
	}, [onDelete, id]);

	return (
		<Grid item xs={12} md={6}>
			<SC.Wrapper>
				<SC.InfoWrapper>
					<SC.Logo src={avatar} />
					<SC.Info>
						<span>{`${firstName} ${lastName}`}</span>
						<span>{email}</span>
					</SC.Info>
				</SC.InfoWrapper>
				<SC.Control>
					<SC.ControlButton
						aria-label="edit"
						size="medium"
						onClick={handleEdit}
					>
						<EditIcon />
					</SC.ControlButton>
					<SC.ControlButton
						onClick={onUserDelete}
						aria-label="delete"
						size="medium"
						customcolor="#f02a2a"
					>
						<DeleteIcon />
					</SC.ControlButton>
				</SC.Control>
			</SC.Wrapper>
		</Grid>
	);
};

export default UserItem;
