import React from 'react';
import {
	createMuiTheme,
	ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

const defaultTheme = createMuiTheme();

export default ({ children }) => {
	const appTheme = createMuiTheme({
		palette: {
			primary: {
				main: 'rgb(80,176,226)',
				dark: '#559ad4',
				gray: 'rgba(0, 0, 0, 0.87)',
				light: '#fff',
				red: '#f96868'
			},

			secondary: {
				main: '#04f795',
				light: '#fff'
			},
			error: { main: '#b00020' }
		},

		props: {
			backgrounds: {
				semiOpaque: 'rgba(18, 18, 18, 0.87)',
				boxShadowColor: 'rgb(0,0,0,0.15)',
				primaryThemeBackground:
					'linear-gradient(to right, #00b2e7 -45%, #04f795 109%)'
			},
			icons: {
				arrowUp: 'rgb(71, 155, 107)',
				arrowDown: 'rgb(134, 44, 33)',
				sortDown: 'rgb(94,253,185)',
				close: 'rgb(106,105,107)'
			},
			buttons: {
				backgroundColor: '#cfcfcf',
				color: '#eee'
			}
		},

		overrides: {
			MuiCheckbox: {
				colorPrimary: {
					'&$checked': {
						color: '#559ad4'
					}
				}
			},
			MuiButton: {
				text: {
					boxShadow: '0 3px 36px 0 rgba(0, 0, 0, 0.04)',
					color: '#fff',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					marginRight: '40px',
					background: 'linear-gradient(to right, #00b2e7 -56%, #04f795 144%)'
				},
				transparent: {
					background: 'transparent'
				}
			},
			MuiFormLabel: {
				root: {
					fontSize: '0.8rem'
				}
			},
			MuiSwitch: {
				colorPrimary: {
					'&$checked': {
						color: 'rgb(90,217,153)'
					}
				},
				colorSecondary: {
					'&$checked': {
						color: 'rgb(249, 25, 17)'
					}
				}
			},
			MuiToolbar: {
				gutters: {
					paddingLeft: 0,
					paddingRight: 0,
					[defaultTheme.breakpoints.up('sm')]: {
						paddingLeft: '16px',
						paddingRight: '16px'
					}
				}
			}
		},
		shadows: ['none']
	});
	return (
		<MuiThemeProvider theme={{ ...appTheme }}>
			<ThemeProvider theme={{ ...appTheme }}>{children}</ThemeProvider>
		</MuiThemeProvider>
	);
};
