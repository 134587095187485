import React, { useContext } from 'react';
import Moment from 'react-moment';
import { NavLink, Link } from 'react-router-dom';
import './ResponseRow.module.scss';
import nonRespondedIcon from '../../assets/images/not-responded.svg';
import respondedIcon from '../../assets/images/responded.svg';
import checkBtnIcn from '../../assets/images/checkBtnIcn.svg';
import { GlobalContext } from '../../context/Context';

const ResponseRow = ({
	match,
	questionId,
	questionText,
	questionDate,
	questionResponded,
	isRespond,
	questionCreatorFirstName,
	questionCreatorLastName,
	questionCreatorId,
	questionCreatorAvatar
}) => {
	const [auth] = useContext(GlobalContext);
	return (
		<div className="list">
			<div className="list__meta">
				<Link
					className={`${!isRespond && 'disabled'}`}
					to={{
						pathname: `${match.url}/${questionId}`,
						search: `?responseId=${
							questionResponded[auth.uid]
						}&type=my-response`,
						data: {
							id: questionId,
							text: questionText,
							date: questionDate,
							createrFirstName: questionCreatorFirstName,
							createrLastName: questionCreatorLastName,
							avatar: questionCreatorAvatar
						}
					}}
				>
					<img
						className="list__meta--icon list__meta--icon-response"
						src={isRespond ? respondedIcon : nonRespondedIcon}
						alt={`id-${questionId}`}
					/>
					<p className="list__meta--title" data-testid="response">
						{questionText}
						<span className="list__meta--date" data-testid="date">
							<Moment format="DD MMM YYYY">{questionDate}</Moment>
						</span>
					</p>
				</Link>
			</div>
			<div className="list__response-state">
				{isRespond ? (
					<div>
						<span className="list__response-state--check">
							<img src={checkBtnIcn} alt="checkbox" />
						</span>
						You responded
					</div>
				) : (
					<NavLink
						to={{
							pathname: `${match.url}/${questionId}/post-response`,
							data: {
								text: questionText,
								date: questionDate,
								createrFirstName: questionCreatorFirstName,
								createrLastName: questionCreatorLastName,
								id: questionId,
								creatorId: questionCreatorId,
								avatar: questionCreatorAvatar
							}
						}}
					>
						<button type="button" className="btn btn-primary pointer">
							Respond
						</button>
					</NavLink>
				)}
			</div>
		</div>
	);
};

export default ResponseRow;
