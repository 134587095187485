import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import GdprContent from '../../components/GdprPage/GdprContent/GdprContent';

export default () => {
	const history = useHistory();

	const goToLoginPage = useCallback(() => {
		history.replace('/');
	}, []);

	return <GdprContent onAccept={goToLoginPage} onDecline={goToLoginPage} />;
};
