import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Moment from 'react-moment';
import * as Style from './Respondents.module.scss';
import FilterBar from './FilterBar/FilterBar';
import avatar from '../../../assets/images/userPlacholder.png';

const Respondents = ({ recipients, question }) => {
	const {
		id,
		text,
		date,
		createrFirstName,
		createrLastName,
		responded
	} = question;
	const [respondedFilter, setRespondedFilter] = useState('all');
	const handleFilter = useCallback(selectedFilter => {
		setRespondedFilter(selectedFilter);
	}, []);
	const mediaQ = useMediaQuery('(max-width:768px)');
	const getFilteredRespondents = () => {
		return respondedFilter === 'all'
			? recipients
			: recipients.filter(
					respondent => respondent.responded === respondedFilter
			  );
	};
	const getResponseId = userID => {
		return responded[userID];
	};
	return (
		<>
			<FilterBar handlefilter={handleFilter} mediaQ={mediaQ} />
			<div className={Style.respondentsContainer}>
				{getFilteredRespondents().length ? (
					getFilteredRespondents().map(respondent => {
						return (
							<Link
								key={respondent.id}
								className={`${Style.respondent} ${mediaQ &&
									Style.fullWidth} ${!respondent.responded && Style.disabled}`}
								to={{
									pathname: `/responses/${id}`,
									search: `?responseId=${getResponseId(respondent.id)}`,
									date: { text, date, createrFirstName, createrLastName }
								}}
							>
								{respondent.responded ? (
									<DoneIcon className={Style.responded} />
								) : (
									<CloseIcon className={Style.pending} />
								)}
								<img src={respondent.avatar || avatar} alt="" />
								<div className={Style.cardInfo}>
									<p>{respondent.name}</p>
									{!!respondent.date && (
										<span>
											<Moment format="DD MMM YYYY">
												{new Date(respondent.date)}
											</Moment>
										</span>
									)}
								</div>
							</Link>
						);
					})
				) : (
					<div className="message_container">
						<p className="message_body">No responses were found.</p>
					</div>
				)}
			</div>
		</>
	);
};

export default Respondents;
