import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, CircularProgress, useMediaQuery } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import axios from 'axios';
import * as firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardsGrid from '../../components/QuestionResponse/CardsGrid/CardsGrid';
import CardsStepper from '../../components/QuestionResponse/Stepper/Stepper';
import AskedQuestion from '../../components/QuestionResponse/AskedQuestion/AskedQuestion';
import AlertComponent from '../../components/Alert/Alert';
import * as FirstStepStyle from './QuestionResponse.module.scss';
import * as SecondStepStyle from './QuestionResponseFinalStep.module.scss';
import img from '../../assets/images/userPlacholder.png';
import { GlobalContext } from '../../context/Context';
import useStyles from '../../components/QuestionResponse/QuestionMaterialStyles/QuestionMaterialStyles';
import CardsGridVertical from '../../components/QuestionResponse/CardsGridVertical/CardsGridVertical';
import * as HintStyle from '../../components/Hint/Hint.module.scss';
import Hint from '../../components/Hint/Hint';

export default ({
	history,
	activeStep,
	setActiveStep,
	setFinalStep,
	cards,
	setSortedCards,
	loadingCards,
	question,
	isQuestionLoading
}) => {
	const classes = useStyles();
	const handleGoingBack = e => {
		e.preventDefault();
		setFinalStep(false);
		setActiveStep(1);
		setSortedCards([]);
	};
	const { data } = history.location;
	const [impactedCards, setImpactedCards] = useState([]);
	const [positiveImpactedCards, setpositiveImpactedCards] = useState([]);
	const [negativeImpactedCards, setnegativeImpactedCards] = useState([]);
	const [neutralImpactedCards, setneutralImpactedCards] = useState([]);
	const [selectedCard, setSelectedCard] = useState(null);
	const [viewComment, setViewComment] = useState(null);
	const [loadSubmit, setLoadSubmit] = useState(false);
	const [alertPopUp, setAlertPopUp] = useState(null);
	const [selectedCardHighlight, setSelectedCardHighlight] = useState(null);
	const [disableNeutralLaneDrop, setDisableNeutralLaneDrop] = useState(false);
	const theme = useTheme();
	const mediaQ = useMediaQuery('(max-width:1025px)');
	const [auth] = useContext(GlobalContext);
	const alertRef = useRef(null);
	const getInitialCards = initialCards => {
		return initialCards.map(card => {
			return { id: uuidv4(), cardID: card.id, isDragDisabled: true };
		});
	};
	useEffect(() => {
		setImpactedCards(cards);
		setneutralImpactedCards(cards);
		setpositiveImpactedCards(getInitialCards(cards));
		setnegativeImpactedCards(getInitialCards(cards));
	}, [cards]);
	const setCardImpact = (impact, draggableId) => {
		setImpactedCards(prevState => {
			const impactCards = [...prevState];
			const cardIndex = prevState.findIndex(card => card.id === draggableId);
			if (cardIndex !== -1) {
				if (impactCards[cardIndex].impact !== impact) {
					impactCards[cardIndex].comment = '';
				}
				impactCards[cardIndex].impact = impact;
			}
			// impactCards[cardIndex].comment = '';
			return impactCards;
		});
	};
	const getCard = id => {
		return { ...impactedCards.find(card => card.id === id) };
	};
	const handleDragStart = event => {
		const { source } = event;

		setDisableNeutralLaneDrop(source.droppableId === 'parent-dropZone');
	};
	const getImpact = destination => {
		const { droppableId } = destination;
		let impact = 0;
		if (droppableId === 'positive-dropZone') {
			impact = 1;
		} else if (droppableId === 'negative-dropZone') {
			impact = -1;
		} else {
			impact = 0;
		}

		return impact;
	};
	const setCardState = (prevState, newCard, index) => {
		const card = [...prevState];
		card[index] = newCard;
		return card;
	};
	const handleCardSelection = card => {
		setSelectedCardHighlight(card);
		setSelectedCard(card);
		setViewComment(card.comment ? card.comment : '');
	};
	const setPositiveCardChange = (
		index,
		sourceID,
		positiveCard,
		negativeCard,
		neutralCard
	) => {
		setpositiveImpactedCards(prevState => {
			return setCardState(
				prevState,
				sourceID === 'parent-dropZone' ? neutralCard : negativeCard,
				index
			);
		});
		if (sourceID === 'parent-dropZone') {
			setneutralImpactedCards(prevState => {
				return setCardState(prevState, positiveCard, index);
			});
		} else {
			setnegativeImpactedCards(prevState => {
				return setCardState(prevState, positiveCard, index);
			});
		}
	};
	const setNegativeCardChange = (
		index,
		sourceID,
		positiveCard,
		negativeCard,
		neutralCard
	) => {
		setnegativeImpactedCards(prevState => {
			return setCardState(
				prevState,
				sourceID === 'parent-dropZone' ? neutralCard : positiveCard,
				index
			);
		});
		if (sourceID === 'parent-dropZone') {
			setneutralImpactedCards(prevState => {
				return setCardState(prevState, negativeCard, index);
			});
		} else {
			setpositiveImpactedCards(prevState => {
				return setCardState(prevState, negativeCard, index);
			});
		}
	};
	const setNeutralCardChange = (
		index,
		sourceID,
		positiveCard,
		negativeCard,
		neutralCard
	) => {
		setneutralImpactedCards(prevState => {
			return setCardState(
				prevState,
				sourceID === 'positive-dropZone' ? positiveCard : negativeCard,
				index
			);
		});
		if (sourceID === 'positive-dropZone') {
			setpositiveImpactedCards(prevState => {
				return setCardState(prevState, neutralCard, index);
			});
		} else {
			setnegativeImpactedCards(prevState => {
				return setCardState(prevState, neutralCard, index);
			});
		}
	};
	const toggleCards = (draggableId, source, destination) => {
		const { index, droppableId: sourceID } = source;
		const { droppableId: destinationID } = destination;
		const impact = getImpact(destination);
		let positiveCard = { ...positiveImpactedCards[index] };
		positiveCard = {
			...positiveCard,
			impact,
			comment: ''
		};
		let negativeCard = { ...negativeImpactedCards[index] };
		negativeCard = {
			...negativeCard,
			impact,
			comment: ''
		};
		let neutralCard = { ...neutralImpactedCards[index] };
		neutralCard = { ...neutralCard, impact, comment: '' };
		setCardImpact(impact, draggableId);

		if (destinationID !== sourceID) {
			// reset card selection
			if (selectedCardHighlight) {
				const { id } = selectedCardHighlight;
				if (draggableId === id) {
					setSelectedCardHighlight(null);
				}
			}
			if (destinationID === 'positive-dropZone') {
				setPositiveCardChange(
					index,
					sourceID,
					positiveCard,
					negativeCard,
					neutralCard
				);
			} else if (destinationID === 'negative-dropZone') {
				setNegativeCardChange(
					index,
					sourceID,
					positiveCard,
					negativeCard,
					neutralCard
				);
			} else {
				setNeutralCardChange(
					index,
					sourceID,
					positiveCard,
					negativeCard,
					neutralCard
				);
			}
		} else if (destinationID !== 'parent-dropZone') {
			handleCardSelection(getCard(draggableId));
		}
	};

	const handleDragEnd = result => {
		const { destination, draggableId, source } = result;
		if (destination) {
			toggleCards(draggableId, source, destination);
		}
	};
	const resetCardsImpact = () => {
		const impactReset = prevState => {
			const impactCards = [...prevState];
			impactCards.forEach((card, index) => {
				impactCards[index].comment = '';
				impactCards[index].impact = 0;
			});
			return impactCards;
		};
		setpositiveImpactedCards(prevState => {
			return impactReset(prevState);
		});
		setnegativeImpactedCards(prevState => {
			return impactReset(prevState);
		});
		setImpactedCards(prevState => {
			return impactReset(prevState);
		});
	};

	const commentHandler = e => {
		const { value } = e.target;
		setViewComment(value);
		const addComment = prevState => {
			const impactCards = [...prevState];
			const cardIndex = prevState.findIndex(
				card => card.id === selectedCard.id
			);
			impactCards[cardIndex].comment = value;
			return impactCards;
		};
		if (!mediaQ) {
			if (selectedCard.impact === 1) {
				setpositiveImpactedCards(prevState => {
					return addComment(prevState);
				});
			} else {
				setnegativeImpactedCards(prevState => {
					return addComment(prevState);
				});
			}
		}
		setImpactedCards(prevState => {
			return addComment(prevState);
		});
	};
	const validateCardsComments = () => {
		let isNotValid = true;
		let areAllCardsNeutral = true;
		impactedCards.forEach(card => {
			if (card.impact !== 0) {
				areAllCardsNeutral = false;
			}
			if (card.impact !== 0 && !card.comment) {
				isNotValid = false;
			}
		});
		const alertMessage = msg => {
			setAlertPopUp(msg);
			if (alertRef.current) {
				alertRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		};
		if (!isNotValid) {
			alertMessage('Please add comments on the impacted cards!');
		}

		if (areAllCardsNeutral) {
			alertMessage('You must impact at least one card!');
		}
		return isNotValid && !areAllCardsNeutral;
	};
	const handleSubmission = async () => {
		if (validateCardsComments()) {
			let answerId;
			setLoadSubmit(true);
			const db = firebase.firestore();
			try {
				await db
					.collection('questions')
					.doc(question.id)
					.collection('answers')
					.add({
						cards: impactedCards,
						date: new Date(),
						first_name: auth.firstName,
						user: auth.uid
					})
					.then(async answer => {
						answerId = answer.id;
						await db
							.collection('questions')
							.doc(question.id)
							.get()
							.then(async currentQuestion => {
								const { responded, ...reset } = currentQuestion.data();
								if (!(auth.uid in responded)) responded[auth.uid] = answerId;
								const updatedQuestion = { responded, ...reset };
								await db
									.collection('questions')
									.doc(question.id)
									.set({
										...updatedQuestion
									});
							});
					});

				resetCardsImpact();
				setAlertPopUp(null);
				history.push('/responses');
			} catch (e) {
				console.log(e.message);
			} finally {
				setLoadSubmit(false);
			}
			if (question.creatorId) {
				try {
					await axios.post('/send_email_new_response', {
						dest: question.creatorId,
						from: auth.uid,
						href: `${window.origin}/responses/${question.id}?responseId=${answerId}`
					});
				} catch (err) {
					console.log(err); // error here
				}
			}
		}
	};
	const handleCardSwitch = (e, card) => {
		setCardImpact(parseInt(e.target.value, 10), card.id);
	};
	const deleteCommentHandler = () => {
		commentHandler({ target: { value: '' } });
	};
	const hideAlertHand = () => {
		setAlertPopUp(null);
	};

	return (
		<div className={`${mediaQ && SecondStepStyle.finalStepContainer}`}>
			<div ref={alertRef}>
				{alertPopUp && (
					<AlertComponent
						message={alertPopUp}
						severityIndicator="warning"
						setHideMessage={hideAlertHand}
					/>
				)}
			</div>
			<Hint
				HintTitle="Indicate which motivator will be impacted positively or negatively."
				HintSubtitle="Drag and drop the cards that will be impacted to the positive or negative area then click on the card to add a comment in to explain why."
			>
				<div className={HintStyle.flexContainer}>
					<FontAwesomeIcon
						className={HintStyle.arrow_indicators__distance}
						size="4x"
						color={theme.props.icons.arrowDown}
						icon={['far', 'arrow-alt-circle-down']}
					/>
					<FontAwesomeIcon
						className={HintStyle.arrow_indicators__distance}
						size="4x"
						color={theme.props.icons.sortDown}
						icon={['far', 'arrow-alt-circle-up']}
					/>
				</div>
			</Hint>
			)}
			{mediaQ ? (
				<CardsGridVertical
					commentHandler={commentHandler}
					handleGoingBack={handleGoingBack}
					handleSubmission={handleSubmission}
					loadSubmit={loadSubmit}
					classes={classes}
					activeStep={activeStep}
					loadingCards={loadingCards}
					data={data}
					question={question}
					isLoading={isQuestionLoading}
					cardImpactChange={(e, card) => handleCardSwitch(e, card)}
					color="primary"
					position="absolute"
					cards={impactedCards}
					handleCardSelection={card => handleCardSelection(card)}
				/>
			) : (
				<>
					<CardsStepper activeStep={activeStep} />
					<Container classes={{ root: classes.container }}>
						<section className={SecondStepStyle.leftBlock}>
							{!loadingCards && (
								<AskedQuestion
									data={data}
									question={question}
									isLoading={isQuestionLoading}
									color="primary"
									position="absolute"
								/>
							)}
						</section>
						<section className={SecondStepStyle.centerBlock}>
							{loadingCards ? (
								<div className={SecondStepStyle.loadingContainer}>
									<CircularProgress
										data-testid="loading-button"
										size={30}
										className={FirstStepStyle.buttonProgress}
									/>
								</div>
							) : (
								<div className={SecondStepStyle.cardsContainer}>
									<CardsGrid
										classes={classes}
										handleDragEnd={handleDragEnd}
										handleDragStart={handleDragStart}
										disableNeutralLaneDrop={disableNeutralLaneDrop}
										getPositiveCards={positiveImpactedCards}
										handleCardSelection={card => handleCardSelection(card)}
										selectedCardHighlight={selectedCardHighlight}
										getNeutralCards={neutralImpactedCards}
										getNegativeCards={negativeImpactedCards}
										handleGoingBack={handleGoingBack}
										handleSubmission={handleSubmission}
										loadSubmit={loadSubmit}
									/>
								</div>
							)}
						</section>
						<section className={SecondStepStyle.rightBlock}>
							{selectedCardHighlight && selectedCard && (
								<>
									<div
										className={`${
											SecondStepStyle.comment
										}  ${selectedCard.impact &&
											(selectedCard.impact === 1
												? SecondStepStyle.positive
												: SecondStepStyle.negative)}`}
									>
										<span>Your Comment</span>
										<div className={SecondStepStyle.commentHeader}>
											<h4>{selectedCard.name}</h4>
											<DeleteOutlineIcon
												size={30}
												onClick={deleteCommentHandler}
											/>
										</div>
										<TextField
											InputProps={{
												className: `${classes.textField} ${
													selectedCard.impact === 1 ? 'positive' : 'negative'
												}`
											}}
											id="standard-multiline-static"
											multiline
											placeholder="Add your comment here"
											rows="4"
											value={viewComment}
											onChange={commentHandler}
										/>
									</div>
									<div className={SecondStepStyle.profile}>
										<img src={auth.avatar || img} alt="User profile" />
										<p>Me</p>
									</div>
								</>
							)}
						</section>
					</Container>
				</>
			)}
		</div>
	);
};
