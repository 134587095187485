import React from 'react';

export default props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="92.315"
		height="92.315"
		viewBox="0 0 92.315 92.315"
		fill="none"
		stroke="#e2e2e2"
		strokeWidth="2px"
		{...props}
	>
		<g
			id="prefix__Group_1259"
			data-name="Group 1259"
			transform="translate(-640 -246.343)"
		>
			<g
				id="prefix__Group_1258"
				data-name="Group 1258"
				transform="translate(0 -33)"
			>
				<g
					id="prefix__Group_1385"
					data-name="Group 1385"
					transform="translate(58)"
				>
					<g id="prefix__Path_63" fill="none" stroke="none" data-name="Path 63">
						<path
							d="M46.157 0A46.157 46.157 0 1 1 0 46.157 46.157 46.157 0 0 1 46.157 0z"
							transform="translate(582 279.343)"
						/>
						<path
							fill="#ededed"
							d="M46.157 5C40.6 5 35.21 6.088 30.14 8.232c-4.9 2.073-9.303 5.041-13.084 8.823-3.782 3.781-6.75 8.183-8.823 13.084C6.088 35.209 5 40.599 5 46.157c0 5.559 1.088 10.948 3.232 16.02 2.073 4.9 5.041 9.302 8.823 13.083 3.781 3.782 8.183 6.75 13.084 8.823 5.07 2.144 10.46 3.232 16.018 3.232 5.559 0 10.948-1.088 16.02-3.232 4.9-2.073 9.302-5.041 13.083-8.823 3.782-3.781 6.75-8.183 8.823-13.084 2.144-5.07 3.232-10.46 3.232-16.019 0-5.558-1.088-10.947-3.232-16.018-2.073-4.9-5.041-9.303-8.823-13.084-3.781-3.782-8.183-6.75-13.084-8.823C57.106 6.088 51.716 5 46.157 5m0-5C71.65 0 92.315 20.665 92.315 46.157c0 25.493-20.665 46.158-46.158 46.158C20.665 92.315 0 71.65 0 46.157 0 20.665 20.665 0 46.157 0z"
							transform="translate(582 279.343)"
						/>
					</g>
					<g
						id="prefix__Group_1387"
						data-name="Group 1387"
						transform="translate(608 303.716)"
					>
						<g
							id="prefix__Group_1386"
							clipPath="url(#prefix__clip-path)"
							data-name="Group 1386"
						>
							<path
								id="prefix__Path_314"
								d="M14.848 11.977v5.573L4.581 21.217A6.149 6.149 0 0 0 .5 27.008v3.866h38.944v-3.866a6.149 6.149 0 0 0-4.081-5.79L25.1 17.55v-5.956"
								className="prefix__cls-4"
								data-name="Path 314"
								transform="translate(.525 12.17)"
							/>
							<ellipse
								id="prefix__Ellipse_142"
								cx="10.248"
								cy="12.298"
								className="prefix__cls-4"
								data-name="Ellipse 142"
								rx="10.248"
								ry="12.298"
								transform="translate(9.992 1.025)"
							/>
							<path
								id="prefix__Path_315"
								d="M25.208 7.93c-.344.043-.582.029-.929.1-3.491.672-5.733-.592-7.654-3.956-1.154 2.21-4.759 3.956-7.719 3.956a8.238 8.238 0 0 1-3.968-.955"
								className="prefix__cls-4"
								data-name="Path 315"
								transform="translate(5.184 4.272)"
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);
