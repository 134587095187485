import React from 'react';
import { useTheme } from '@material-ui/core';
import {
	StyledContentContainer,
	StyledDivider,
	StyledFooter,
	StyledLink,
	StyledTypography
} from './partials';

export default () => {
	const theme = useTheme();

	return (
		<StyledFooter>
			<StyledDivider />
			<StyledContentContainer>
				<StyledTypography color={theme.palette.primary.light} component="h4">
					© Management 3.0
				</StyledTypography>
				<StyledLink
					href="https://management30.com"
					target="_blank"
					color={theme.palette.primary.light}
				>
					management30.com
				</StyledLink>
			</StyledContentContainer>
		</StyledFooter>
	);
};
