import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

if (!firebase.apps || !firebase.apps.length) {
	// Initialize Firebase
	firebase.initializeApp({
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		authDomain: process.env.REACT_APP_AUTH_DOMAIN,
		databaseURL: process.env.REACT_APP_DATABASE_URL,
		measurementId: process.env.REACT_APP_MEASUREMENT_ID,
		messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
		projectId: process.env.REACT_APP_PROJECT_ID,
		storageBucket: process.env.REACT_APP_STORAGE_BUCKET
	});
}

export const db = firebase.firestore();

export default firebase;
