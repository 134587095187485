import * as firebase from 'firebase/app';
import { convertToBlob } from './imageHelper';
import { knownRoles } from '../constants/constants';

const reauthenticate = currentPassword => {
	const user = firebase.auth().currentUser;
	const cred = firebase.auth.EmailAuthProvider.credential(
		user.email,
		currentPassword
	);
	return user.reauthenticateWithCredential(cred);
};

const uploadFile = blob => {
	return new Promise((resolve, reject) => {
		if (!blob) {
			return reject(new Error('Please provide image url'));
		}
		const user = firebase.auth().currentUser;
		const storageRef = firebase.storage().ref();
		const avatarRef = storageRef.child(`avatars/${user.uid}.jpg`);

		return avatarRef
			.put(blob)
			.then(() => {
				storageRef
					.child(`avatars/${user.uid}.jpg`)
					.getDownloadURL()
					.then(resolve);
			})
			.catch(reject);
	});
};
const removeFile = () => {
	return new Promise((resolve, reject) => {
		const user = firebase.auth().currentUser;
		const storageRef = firebase.storage().ref();
		const avatarRef = storageRef.child(`avatars/${user.uid}.jpg`);

		return avatarRef
			.delete()
			.then(resolve)
			.catch(reject);
	});
};

export const changePassword = async (oldPassword, newPassword) => {
	try {
		await reauthenticate(oldPassword);
		const user = firebase.auth().currentUser;
		await user.updatePassword(newPassword);
		return Promise.resolve();
	} catch (exc) {
		return Promise.reject(exc);
	}
};

function updateUserProfileData(profileData = {}) {
	const user = firebase.auth().currentUser;

	return user.updateProfile(profileData);
}

function updateUserInfo(userData = {}) {
	const { uid } = firebase.auth().currentUser;

	return firebase
		.firestore()
		.collection('users')
		.doc(uid)
		.update(userData);
}

export const changeAvatar = async avatarUrl => {
	try {
		const blobImg = convertToBlob(avatarUrl);
		const imageUrl = await uploadFile(blobImg);

		await updateUserProfileData({ photoURL: imageUrl });
		return updateUserInfo({ avatar: imageUrl });
	} catch (exc) {
		return Promise.reject(exc);
	}
};

export const removeAvatar = async () => {
	try {
		await removeFile();
		const user = firebase.auth().currentUser;
		await user.updateProfile({
			photoURL: null
		});
		return Promise.resolve();
	} catch (exc) {
		return Promise.reject(exc);
	}
};

export const checkRole = (role, validRoles) => {
	if (!validRoles || !validRoles.length) {
		return true;
	}
	if (!role || (validRoles && !Array.isArray(validRoles))) {
		return false;
	}

	return validRoles.includes(role);
};

export const getAuthToken = forceRefresh => {
	if (firebase.auth().currentUser) {
		return firebase.auth().currentUser.getIdToken(forceRefresh);
	}

	return Promise.resolve();
};

export const checkRoleAdmin = authData => {
	const { role } = authData || {};

	return !!(role === knownRoles.ADMIN.id || role === knownRoles.SUPER_ADMIN.id);
};
