import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	container: {
		minWidth: '100%',
		display: 'flex',
		padding: '0 10px'
	},
	grid: {
		// margin: 'auto',
		// display: 'flex',
		// width: '75%'
	},
	paper: {
		marginBottom: '1.8rem',
		'&:last-child': {
			marginBottom: 0
		},
		'&:nth-child(2)': {
			justifyContent: 'center'
		},
		textAlign: 'center',
		color: theme.palette.text.secondary,
		backgroundColor: 'transparent'
	},
	finishButton: {
		background: theme.props.backgrounds.primaryThemeBackground,
		flexGrow: '3'
	},
	backButton: {
		background: 'transparent',
		color: theme.palette.secondary.main,
		borderColor: theme.palette.secondary.main,
		flexGrow: '1'
	},
	textField: {
		width: '100%',
		'&.positive': {
			color: theme.palette.primary.gray
		},
		'&.negative': {
			color: theme.palette.primary.light
		}
	},
	alertPopUp: {
		position: 'absolute',
		zIndex: 10,
		width: '30%',
		right: 0
	}
}));

export default useStyles;
