/* eslint-disable consistent-return */
import { useState } from 'react';

import { RECIPIENTS_TYPES } from '../constants/constants';

export default function() {
	const [type, setType] = useState(RECIPIENTS_TYPES.GUEST);

	return [type, setType];
}
