import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
	dialog: {
		'& .MuiDialog-paper': {
			background: props =>
				props.impact === -1
					? theme.palette.primary.red
					: theme.palette.secondary.main,
			color: theme.palette.primary.gray
		}
	},
	textField: {
		width: '100%'
	},
	buttons: {
		'& .MuiButtonBase-root': {
			background: 'transparent',
			color: theme.palette.primary.gray
		}
	}
}));

const CommentDialog = ({
	card,
	handleClose,
	commentHandler,
	isEditable = true
}) => {
	const classes = useStyles(card);
	const [fullWidth] = React.useState(true);
	const [maxWidth] = React.useState('sm');
	const [comment, setComment] = useState(null);

	useEffect(() => {
		setComment(card.comment);
	}, [card.comment]);
	const commentChange = e => {
		setComment(e.target.value);
	};
	const handleSave = () => {
		commentHandler({
			target: {
				value: comment
			}
		});
		handleClose();
	};
	return (
		<Dialog
			className={classes.dialog}
			open={!!card}
			onClose={handleClose}
			fullWidth={fullWidth}
			maxWidth={maxWidth}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">
				{card.name.toUpperCase()}
			</DialogTitle>
			<DialogContent>
				<TextField
					className={classes.textField}
					id="standard-multiline-static"
					multiline
					placeholder="Add your comment here"
					rows="4"
					value={comment}
					onChange={commentChange}
					disabled={!isEditable}
				/>
			</DialogContent>
			<DialogActions className={classes.buttons}>
				<Button onClick={handleClose} color="primary">
					{isEditable ? 'Cancel' : 'Close'}
				</Button>
				{isEditable && (
					<Button onClick={handleSave} color="primary">
						Save
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default CommentDialog;
