import React, { useContext, useState } from 'react';
import * as firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import {
	AppBar,
	IconButton,
	Button,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import * as headerStyle from './header.module.scss';
import logo from '../../assets/images/logo.svg';
import { GlobalContext } from '../../context/Context';
import GameInfoModal from '../GameInfoModal/GameInfoModal';
import Profile from '../Profile/Profile';
import { checkRoleAdmin } from '../../helpers/authHelper';

const useStyles = makeStyles(() => ({
	menuList: {
		'&.MuiMenu-list': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		}
	},

	userNameIcon: {
		fontSize: '40px',
		marginRight: '12px'
	},
	userName: {
		padding: 0,
		'&:hover': {
			background: 'transparent'
		}
	},
	avatar: {
		'&:hover': {
			background: 'transparent'
		}
	},
	avatarButton: {
		background: 'transparent',
		margin: '0',
		boxShadow: 'none'
	},
	avatarImage: {
		width: '37px',
		height: '37px',
		marginRight: '12px'
	},
	managementWrapper: {
		display: 'flex',
		marginRight: '20px'
	},
	managementIcon: {
		border: '3px solid white',
		marginRight: '6px',
		color: 'white',
		padding: '2px',
		'font-family': 'Montserrat',
		'font-size': '12px',
		'font-weight': '600',
		'font-stretch': 'normal',
		'font-style': 'normal',
		'line-height': '1.25',
		'letter-spacing': 'normal',
		'text-align': 'center'
	}
}));

export default function Header() {
	const [auth] = useContext(GlobalContext);
	const [profileMenuButton, setProfileMenuButton] = useState(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isProfileOpen, setProfileOpen] = useState(false);
	const mobileMediaQuery = useMediaQuery('(min-width:768px)');
	const isAnonymous = auth?.anonymous;
	const isAuth = auth?.uid && !isAnonymous;
	const theme = useTheme();
	const classes = useStyles();

	const handleMenuOpen = event => {
		setProfileMenuButton(event.currentTarget);
		setIsMenuOpen(true);
	};
	const handleMenuClose = () => {
		setProfileMenuButton(null);
		setIsMenuOpen(false);
	};
	const handlerLogout = () => {
		setIsMenuOpen(false);
		firebase.auth().signOut();
	};

	const menuId = 'primary-search-account-menu';

	const avatar = (
		<IconButton className={classes.avatar}>
			{isAuth && !auth.avatar && (
				<AccountCircle className={classes.userNameIcon} />
			)}
			{isAuth && auth.avatar && (
				<Avatar
					alt="avatar"
					src={auth.avatar}
					className={classes.avatarImage}
					classes={{
						root: 'avatar-wrapper'
					}}
				/>
			)}
			<Typography
				component="h4"
				data-testid="username"
				className={classes.userName}
				style={{
					color: mobileMediaQuery
						? theme.palette.primary.light
						: theme.palette.primary.gray
				}}
				classes={{
					root: 'avatar-title'
				}}
			>
				{auth && `${auth.firstName} ${auth.lastName}`}
			</Typography>
		</IconButton>
	);

	const logoutLink = (
		<Link
			className={headerStyle.headerLink}
			data-testid="loginLink"
			onClick={handlerLogout}
			to="/"
		>
			Logout
		</Link>
	);
	const renderMenu = (
		<Menu
			classes={{ list: classes.menuList }}
			anchorEl={profileMenuButton}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
			data-testid="menu"
		>
			<MenuItem
				onClick={() => setProfileOpen(true)}
				className={classes.userName}
			>
				{avatar}
			</MenuItem>
			<MenuItem>
				<GameInfoModal />
			</MenuItem>
			<MenuItem>{logoutLink}</MenuItem>
		</Menu>
	);
	return (
		<>
			<AppBar
				className={headerStyle.appBarWrapper}
				data-testid="header"
				position="static"
				elevation={0}
			>
				<Toolbar className={`${headerStyle.headerWrapper}`}>
					<div className={headerStyle.headerTitle}>
						<Link
							data-testid="headerLink"
							className={headerStyle.headerLink}
							to="/"
						>
							<img src={logo} alt="logo" />
							Moving Motivators
						</Link>
					</div>
					{isAuth && (
						<>
							<Profile
								open={isProfileOpen}
								closeProfile={() => setProfileOpen(false)}
							/>
							{mobileMediaQuery ? (
								<div className={headerStyle.profileContainer}>
									{checkRoleAdmin(auth) && (
										<div className={classes.managementWrapper}>
											<Link
												data-testid="users-managements-link"
												to="/users-management"
											>
												<IconButton
													className={classes.managementIcon}
													aria-label="settings"
													size="small"
												>
													<SettingsIcon />
												</IconButton>
												Users Management
											</Link>
										</div>
									)}
									<GameInfoModal />
									<Button
										classes={{ root: classes.avatarButton }}
										onClick={() => setProfileOpen(true)}
									>
										{avatar}
									</Button>
									<div>
										<Link
											className="btn btn-outlined"
											data-testid="loginLink"
											onClick={handlerLogout}
											to="/"
										>
											Logout
										</Link>
									</div>
								</div>
							) : (
								<div>
									<IconButton
										onClick={handleMenuOpen}
										className={headerStyle.headerIcon}
									>
										<MoreIcon />
									</IconButton>
								</div>
							)}
						</>
					)}
				</Toolbar>
			</AppBar>
			{isAuth && isMenuOpen && !mobileMediaQuery && renderMenu}
		</>
	);
}
