import React, { useCallback, useState } from 'react';
import { Container, Grid, CircularProgress } from '@material-ui/core';
import IconAdd from '@material-ui/icons/Add';
import _debounce from 'lodash/debounce';
import { Close as CloseIcon } from '@material-ui/icons';
import * as SC from './partials';
import useFetchUserList from '../../hooks/useFetchUserList';
import UserList from '../../components/UserManagement/UserList/UserList';
import UserEdit from '../../components/UserManagement/UserEdit/UserEdit';

const UserManagement = () => {
	const [query, setQuery] = useState('');
	const [users, isLoading] = useFetchUserList();
	const [userEditData, setUserEditData] = useState(null);

	const handleSetQuery = _debounce(copy => {
		const searchCopy = copy.toLowerCase();
		setQuery(searchCopy);
	}, 500);

	const handleChangeQuery = useCallback(
		e => {
			const searchQuery = e.target.value;
			handleSetQuery(searchQuery);
		},
		[query]
	);

	const closeEditDialog = useCallback(() => {
		setUserEditData(null);
	}, []);

	const openCreateDialog = useCallback(() => {
		setUserEditData({});
	}, []);

	const openEditDialog = useCallback(data => {
		setUserEditData(data);
	}, []);

	return (
		<Container className="container" disableGutters={false} maxWidth="md">
			<SC.Header>
				<SC.Title>Registered Users</SC.Title>
				<SC.AddButton
					variant="contained"
					startIcon={<IconAdd color="inherit" />}
					onClick={openCreateDialog}
				>
					<span>Add User</span>
				</SC.AddButton>
			</SC.Header>
			<Grid item xs={12}>
				<SC.SearchInput
					id="user-manage-search"
					fullWidth
					placeholder="Search users"
					startAdornment={<SC.SearchIcon />}
					disableUnderline
					autoComplete="off"
					onChange={handleChangeQuery}
				/>
			</Grid>
			<div className="scroll-container">
				<SC.ScrollWrapper className="infinite-scroll">
					{isLoading ? (
						<SC.LoadWrapper>
							<CircularProgress
								data-testid="loading-button"
								size={30}
								color="secondary"
							/>
						</SC.LoadWrapper>
					) : (
						<UserList
							query={query}
							users={users}
							openEditDialog={openEditDialog}
							closeEditDialog={closeEditDialog}
						/>
					)}
				</SC.ScrollWrapper>
			</div>
			<SC.EditDialog
				classes={{ paper: 'paper' }}
				open={!!userEditData}
				onClose={closeEditDialog}
			>
				<SC.CloseButton aria-label="close" onClick={closeEditDialog}>
					<CloseIcon />
				</SC.CloseButton>
				{!!userEditData && (
					<UserEdit data={userEditData} handleClose={closeEditDialog} />
				)}
			</SC.EditDialog>
		</Container>
	);
};

export default UserManagement;
