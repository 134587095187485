import React from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import * as SecondStepStyle from '../../../containers/QuestionResponse/QuestionResponseFinalStep.module.scss';

const QuestionControl = ({
	handleGoingBack,
	classes,
	handleSubmission,
	loadSubmit
}) => {
	return (
		<>
			<Box className={SecondStepStyle.buttons_wrapper}>
				<Button
					onClick={handleGoingBack}
					classes={{ root: classes.backButton }}
					variant="outlined"
				>
					Back
				</Button>
				<Button
					classes={{ root: classes.finishButton }}
					onClick={handleSubmission}
				>
					Finish and Submit
					{loadSubmit && (
						<CircularProgress data-testid="loading-button" size={24} />
					)}
				</Button>
			</Box>
			<Box className={SecondStepStyle.confirmation_wrapper}>
				<p>Any checks needed before submission?</p>
			</Box>
		</>
	);
};

export default QuestionControl;
