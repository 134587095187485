import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import * as Style from '../../../containers/QuestionResponse/QuestionResponse.module.scss';
import StyledBox from './CardSlot.style';

export default ({ children, slot }) => {
	return (
		<Droppable droppableId={slot.id}>
			{(provided, snapshot) => (
				<StyledBox
					className={`slot ${Style.cardWrapper}`}
					ref={provided.innerRef}
					{...provided.droppableProps}
					isDraggingOver={snapshot.isDraggingOver}
				>
					{children}
					{provided.placeholder}
				</StyledBox>
			)}
		</Droppable>
	);
};
