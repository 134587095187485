import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import Moment from 'react-moment';
import * as Style from './ReviewResponse.module.scss';
import ReviewCards from '../ReviewCards/ReviewCards';
import img from '../../../assets/images/userPlacholder.png';
import CommentDialog from '../../QuestionResponse/CommentDialog/CommentDialog';
import { GlobalContext } from '../../../context/Context';

const ReviewResponse = ({
	data,
	question,
	isQuestionLoading,
	answer,
	isLoadingAnswer,
	mobileMediaQuery
}) => {
	const askedData = data || question;
	const avatar = (askedData && askedData.avatar) || img;
	const { cards, firstName, lastName, date } = answer;
	const { goBack } = useHistory();
	const [selectedCard, setSelectedCard] = useState(null);
	const [openCommentDialog, setOpenCommentDialog] = useState(false);
	const [auth] = useContext(GlobalContext);
	const handleBackButton = () => {
		goBack();
	};
	const handleCloseDialog = () => {
		setOpenCommentDialog(false);
	};
	const handleCardSelection = card => {
		setSelectedCard(card);
		if (mobileMediaQuery && card.comment) setOpenCommentDialog(true);
	};
	return (
		<div className={Style.myResponseContainer}>
			{isLoadingAnswer ? (
				<div className="loader" />
			) : (
				<>
					{selectedCard && openCommentDialog && (
						<CommentDialog
							card={selectedCard}
							handleClose={handleCloseDialog}
							isEditable={false}
						/>
					)}
					<section className={Style.responseLeft}>
						{selectedCard && selectedCard.comment && !mobileMediaQuery && (
							<div className={Style.commentContainer}>
								<div className={Style.profile}>
									<img src={avatar} alt="User profile" />
									<div className={Style.profileDescription}>
										<p>
											{firstName} {lastName}
										</p>
										<span>
											<Moment format="DD MMM YYYY">{date}</Moment>
										</span>
									</div>
								</div>
								<div
									className={`${Style.comment}  ${selectedCard.impact &&
										(selectedCard.impact === 1
											? Style.positive
											: Style.negative)}`}
								>
									<h4>{selectedCard.name}</h4>
									<p>{selectedCard.comment}</p>
								</div>
							</div>
						)}
					</section>
					<section className={Style.responseCenter}>
						<div className={Style.title}>
							<p>
								<span>{firstName} </span>
								sorted their cards this way
							</p>
						</div>
						<ReviewCards
							cards={cards}
							selectCard={card => handleCardSelection(card)}
							mobileMediaQuery={mobileMediaQuery}
						/>
						<Button className={Style.backButton} onClick={handleBackButton}>
							Back
						</Button>
					</section>
					<section className={Style.responseRight}>
						{isQuestionLoading ? (
							<div className="loader" />
						) : (
							<div className={Style.myQuestionWrapper}>
								<span>Your Question</span>
								<div className={Style.question}>
									<p className={Style.text}>{askedData.text}</p>
								</div>
								<div className={Style.profile}>
									<img src={auth.avatar || img} alt="User profile" />
									<div className={Style.profileDescription}>
										<p>Me</p>
										<p>
											<Moment format="DD MMM YYYY">{askedData.date}</Moment>
										</p>
									</div>
								</div>
							</div>
						)}
					</section>
				</>
			)}
		</div>
	);
};

export default ReviewResponse;
