import React, { useCallback } from 'react';
import { useTheme } from '@material-ui/core';
import {
	StyledButton,
	StyledContentContainer,
	StyledTypography
} from './partials';
import { RECIPIENTS_TYPES } from '../../constants/constants';

export default ({ currentType: type, handleClick: setType }) => {
	const theme = useTheme();
	const handleSelectGuest = useCallback(
		() => setType(RECIPIENTS_TYPES.GUEST),
		[]
	);
	const handleSelectUsers = useCallback(
		() => setType(RECIPIENTS_TYPES.USERS),
		[]
	);
	const isGuestSelected = type === RECIPIENTS_TYPES.GUEST;

	return (
		<StyledContentContainer>
			<StyledTypography
				color={theme.palette.primary.light}
				component="span"
				className="question-hint"
			>
				The question is for
			</StyledTypography>
			<StyledButton
				onClick={handleSelectGuest}
				isActive={isGuestSelected}
				className="recipients-type-btn"
				size="large"
			>
				<StyledTypography
					color={
						isGuestSelected
							? theme.palette.primary.dark
							: theme.palette.primary.light
					}
					component="h4"
				>
					a guest
				</StyledTypography>
			</StyledButton>
			<StyledButton
				onClick={handleSelectUsers}
				isActive={!isGuestSelected}
				className="recipients-type-btn"
				size="large"
			>
				<StyledTypography
					color={
						!isGuestSelected
							? theme.palette.primary.dark
							: theme.palette.primary.light
					}
					component="h4"
				>
					an existing user
				</StyledTypography>
			</StyledButton>
		</StyledContentContainer>
	);
};
