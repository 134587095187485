import React, { useState, useCallback } from 'react';
import { Grid, Select, FormHelperText } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import DefaultAvatar from '../../Icons/DefaultAvatarIcon';
import * as SC from './partials';
import {
	knownRoles,
	getPossibleRoles,
	requiredFieldError,
	emailFieldError,
	emailRegEx
} from '../../../constants/constants';
import useSetUser from '../../../hooks/useSetUser';
import { resetPasswordRequest } from '../../../helpers/api';

const defaultUserData = {
	firstName: '',
	lastName: '',
	email: '',
	role: knownRoles.RECIPIENT.id
};

const renderRoles = () => {
	const possibleRoles = getPossibleRoles();
	let roles = Object.keys(possibleRoles);

	roles = roles.map(role => {
		const roleData = possibleRoles[role];
		return (
			<SC.StyledItem
				className="role-item"
				key={roleData.id}
				value={roleData.id}
			>
				{roleData.title}
			</SC.StyledItem>
		);
	});
	roles.unshift(
		<SC.StyledItem
			id="default-item"
			className="role-item"
			key="defaultRoleKey"
			value=""
		>
			Select role
		</SC.StyledItem>
	);

	return roles;
};

const UserEdit = props => {
	const { data, handleClose } = props;

	const editMode = !!data?.id;

	const { register, handleSubmit, errors, control, setValue } = useForm({
		defaultValues: { ...defaultUserData, ...data } || defaultUserData
	});
	const [setUserData, isLoading] = useSetUser(data, {
		onSuccess: handleClose
	});
	const [userAvatar, setUserAvatar] = useState(data.avatar);
	const [isReseted, setIsReseted] = useState(false);

	const onSubmit = fields => {
		setUserData(fields);
	};

	const handleAvatarDelete = useCallback(() => {
		if (userAvatar) {
			setUserAvatar(null);
			register('avatar');
			setValue('avatar', '');
		}
	}, [register, setValue, userAvatar]);

	const handleResetPassword = useCallback(() => {
		setIsReseted(true);
		resetPasswordRequest(data?.id);
	}, []);

	return (
		<SC.Wrapper onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<SC.Headline>{editMode ? 'Edit User' : 'New User'}</SC.Headline>
				</Grid>
				<SC.AvatarWrapper>
					{editMode ? (
						<>
							<SC.UserAvatar size="large" alt="avatar" src={userAvatar} />
							{!!userAvatar && (
								<SC.DelImageButton
									size="medium"
									variant="outlined"
									onClick={handleAvatarDelete}
								>
									Remove Photo
								</SC.DelImageButton>
							)}
						</>
					) : (
						<DefaultAvatar />
					)}
				</SC.AvatarWrapper>
				<Grid item xs={12} sm={6}>
					<SC.StyledTextField
						name="firstName"
						variant="outlined"
						disabled={editMode}
						autoComplete="off"
						placeholder="First name"
						inputRef={register({
							required: requiredFieldError
						})}
						fullWidth
						error={errors.firstName?.type}
						helperText={errors.firstName?.message}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SC.StyledTextField
						name="lastName"
						variant="outlined"
						disabled={editMode}
						autoComplete="off"
						placeholder="Last name"
						inputRef={register({
							required: requiredFieldError
						})}
						fullWidth
						error={errors.lastName?.type}
						helperText={errors.lastName?.message}
					/>
				</Grid>
				<Grid item xs={12}>
					<SC.StyledTextField
						name="email"
						type="email"
						variant="outlined"
						disabled={editMode}
						autoComplete="off"
						placeholder="User email"
						inputRef={register({
							required: requiredFieldError,
							pattern: {
								value: emailRegEx,
								message: emailFieldError
							}
						})}
						fullWidth
						error={errors.email?.type}
						helperText={errors.email?.message}
					/>
				</Grid>
				<Grid item xs={12}>
					<SC.RuleLabel>User role</SC.RuleLabel>
					<SC.RoleSelect variant="outlined">
						<Controller
							as={
								<Select labelId="dropdown-label" id="dropdown" displayEmpty>
									{renderRoles()}
								</Select>
							}
							name="role"
							control={control}
							rules={{ required: requiredFieldError }}
						/>
						{!!errors.role?.message && (
							<FormHelperText error>{errors.role?.message}</FormHelperText>
						)}
					</SC.RoleSelect>
				</Grid>
				<Grid item xs={12}>
					{editMode ? (
						<SC.ResetBox>
							<SC.ResetCopy>
								<span>Password Resetting for this user</span>
								<p>
									This will reset account password and inform the user with the
									new one through email.
								</p>
							</SC.ResetCopy>
							<SC.ResetButton
								size="medium"
								variant="outlined"
								onClick={handleResetPassword}
								disabled={isReseted}
							>
								Reset Password
							</SC.ResetButton>
						</SC.ResetBox>
					) : (
						<SC.ConfirmCopy>
							<span>Confirmation email will be sent to the user</span>
							<p>
								An email will be sent to the user to make him set his/her
								password.
							</p>
						</SC.ConfirmCopy>
					)}
				</Grid>
				<Grid item xs={12}>
					<SC.SubmitButton
						type="submit"
						data-testid="set_password-button"
						disabled={isLoading}
					>
						{editMode ? 'Save changes' : 'Create user'}
					</SC.SubmitButton>
				</Grid>
			</Grid>
		</SC.Wrapper>
	);
};

export default UserEdit;
