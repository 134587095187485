import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export default styled(TextField)`
	.MuiOutlinedInput-root {
		border-radius: 2rem;
		color: #fff;
		.MuiOutlinedInput-notchedOutline {
			border-color: #fff;
		}
		textarea {
			padding: 1rem;
		}
		&:hover fieldset {
			border-color: #04f795;
		}
		&.Mui-focused fieldset {
			border-color: #fff;
		}
	}
`;
