import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import Typography from '@material-ui/core/Typography';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';

const useStepperStyles = makeStyles(theme => ({
	root: {
		backgroundColor: 'transparent',
		width: '100%',
		margin: '0 auto',
		'& .MuiStep-completed': {
			'& .MuiStepLabel-iconContainer': {
				backgroundColor: theme.palette.secondary.main,
				border: 'none'
			}
		}
	},
	stepLabel: {
		'& .MuiStepLabel-iconContainer': {
			border: '2px #fff dashed',
			color: theme.palette.common.white,
			borderRadius: '25px',
			padding: '10px',
			position: 'relative',
			top: '-10px'
		},
		'& .MuiStepLabel-labelContainer': {
			'& .MuiStepLabel-label': {
				color: theme.palette.secondary.main,
				opacity: '0.5',
				margin: '0'
			}
		}
	},
	stepContent: {
		border: 'none',
		color: theme.palette.common.white,
		fontSize: '12px',
		textAlign: 'center',
		opacity: '0.5',
		margin: 'auto',
		width: '50%'
	}
}));
const CardsStepper = ({ activeStep, hideContent }) => {
	const classes = useStepperStyles();
	return (
		<Stepper className={classes.root} alternativeLabel activeStep={activeStep}>
			<Step>
				<StepLabel
					className={classes.stepLabel}
					StepIconComponent={ViewCarouselIcon}
				>
					Start sorting cards
				</StepLabel>
				{!hideContent && (
					<Typography className={classes.stepContent}>
						Sort the cards based on how it was affected
					</Typography>
				)}
			</Step>
			<Step>
				<StepLabel
					className={classes.stepLabel}
					StepIconComponent={FlipToBackIcon}
				>
					Set the effect
				</StepLabel>
				{!hideContent && (
					<Typography className={classes.stepContent}>
						Set which motivator was effected in a good way and which was not
					</Typography>
				)}
			</Step>
		</Stepper>
	);
};

export default CardsStepper;
