import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import { useMediaQuery } from '@material-ui/core';
import ReviewMyResponse from '../../components/ViewResponse/ReviewMyResponse/ReviewMyResponse';
import ReviewResponse from '../../components/ViewResponse/ReviewResponse/ReviewResponse';
import useFetchQuestion from '../../hooks/useFetchQuestion';
import useFetchAnswer from '../../hooks/useFetchAnswer';
import AlertComponent from '../../components/Alert/Alert';

const ViewResponse = ({ history, ...rest }) => {
	const { search } = useLocation();
	const { data } = history.location;
	const query = queryString.parse(search);
	const { type, responseId } = query;
	const params = useParams();
	const { questionId } = params;
	const [question, isQuestionLoading] = useFetchQuestion({}, questionId, !data);
	const [vieError, setViewError] = useState(true);
	const [answer, isLoadingAnswer, error, isError] = useFetchAnswer(
		{},
		questionId,
		responseId
	);

	const { cards } = answer || { cards: [] };
	const mobileMediaQuery = useMediaQuery('(max-width:1025px)');
	const hideError = () => {
		setViewError(false);
	};
	return (
		<>
			{isError && vieError && (
				<AlertComponent
					message={error}
					severityIndicator="error"
					setHideMessage={hideError}
				/>
			)}
			{!isError &&
				(type ? (
					<ReviewMyResponse
						{...rest}
						data={data}
						question={question}
						isQuestionLoading={isQuestionLoading}
						cards={cards}
						mobileMediaQuery={mobileMediaQuery}
						isLoadingAnswer={isLoadingAnswer}
					/>
				) : (
					<ReviewResponse
						{...rest}
						data={data}
						question={question}
						isQuestionLoading={isQuestionLoading}
						answer={answer}
						mobileMediaQuery={mobileMediaQuery}
						isLoadingAnswer={isLoadingAnswer}
					/>
				))}
		</>
	);
};

export default ViewResponse;
