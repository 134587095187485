import React from 'react';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as Style from './CardsGridVertical.module.scss';
import AskedQuestion from '../AskedQuestion/AskedQuestion';
import CardsStepper from '../Stepper/Stepper';
import QuestionControl from '../QuestionControl/QuestionControl';
import PositiveCommentIcon from '../../../assets/images/positive_comment.svg';
import NegativeCommentIcon from '../../../assets/images/negative_comment.svg';
import CommentDialog from '../CommentDialog/CommentDialog';

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiFormControlLabel-root': {
			margin: 5
		}
	},
	negativeRadioButton: {
		'& .Mui-checked': {
			color: theme.palette.primary.red
		}
	},
	neutralRadioButton: {
		'& .Mui-checked': {
			color: theme.palette.primary.main
		}
	}
}));
const CardsGridVertical = ({
	commentHandler,
	classes,
	loadSubmit,
	handleSubmission,
	handleGoingBack,
	activeStep,
	loadingCards,
	data,
	question,
	isQuestionLoading,
	cards,
	cardImpactChange,
	handleCardSelection
}) => {
	const materialClasses = useStyles();
	const [openCommentDialog, setOpenCommentDialog] = React.useState(null);
	const handleClickOpen = card => {
		handleCardSelection(card);
		setOpenCommentDialog(card);
	};

	const handleClose = () => {
		setOpenCommentDialog(null);
	};
	const handleChange = (e, card) => {
		cardImpactChange(e, card);
	};
	return (
		<div className={Style.Container}>
			{!loadingCards && (
				<AskedQuestion
					data={data}
					question={question}
					isLoading={isQuestionLoading}
					color="primary"
				/>
			)}
			<CardsStepper
				activeStep={activeStep}
				hideContent
				margin="0px"
				width="100%"
			/>
			{openCommentDialog && (
				<CommentDialog
					card={openCommentDialog}
					handleClose={handleClose}
					commentHandler={commentHandler}
				/>
			)}
			{loadingCards ? (
				<div className="loader" />
			) : (
				<>
					<div className={Style.cardsContainer}>
						{cards.map(card => {
							return (
								<div key={card.id} className={Style.cardWrapper}>
									<div className={Style.imgWrapper}>
										{card.comment &&
											(card.impact === -1 ? (
												<img
													src={NegativeCommentIcon}
													className={Style.commentIcon}
													alt=""
												/>
											) : (
												<img
													src={PositiveCommentIcon}
													className={Style.commentIcon}
													alt=""
												/>
											))}
										<img src={card.imgs.desktop} alt={card.name} />
									</div>
									<div className={Style.cardControl}>
										<FormControl
											component="fieldset"
											className={materialClasses.root}
										>
											<RadioGroup
												row
												aria-label="position"
												name="position"
												value={card.impact}
												onChange={e => handleChange(e, card)}
											>
												<FormControlLabel
													value={-1}
													control={<Radio color="secondary" />}
													label="Negative"
													labelPlacement="top"
													className={materialClasses.negativeRadioButton}
												/>
												<FormControlLabel
													value={0}
													control={<Radio color="secondary" />}
													label="Neutral"
													labelPlacement="top"
													className={materialClasses.neutralRadioButton}
												/>
												<FormControlLabel
													value={1}
													control={<Radio color="secondary" />}
													label="Positive"
													labelPlacement="top"
												/>
											</RadioGroup>
										</FormControl>
										{card.impact !== 0 && (
											<InsertCommentIcon
												onClick={() => handleClickOpen(card)}
												className={Style.commentIcon}
												size={30}
											/>
										)}
									</div>
								</div>
							);
						})}
					</div>
					<QuestionControl
						handleGoingBack={handleGoingBack}
						handleSubmission={handleSubmission}
						classes={classes}
						loadSubmit={loadSubmit}
					/>
				</>
			)}
		</div>
	);
};

export default CardsGridVertical;
