import { useEffect, useState } from 'react';

import * as firebase from 'firebase/app';

export default subscriptionId => {
	const [users, setUsers] = useState([]);
	const [isLoading, setLoading] = useState(true);

	const db = firebase.firestore();
	const collectionRef = db
		.collection('users')
		.where('subscription_id', '==', subscriptionId);

	useEffect(() => {
		let active = true;

		if (active) {
			(async () => {
				const allUsersSnapshot = await collectionRef.get();

				const allUsers = allUsersSnapshot.docs
					.map(doc => {
						const docData = doc.data();
						return {
							id: doc.id,
							firstName: docData.first_name,
							lastName: docData.last_name,
							deleted: docData.deleted
						};
					})
					.filter(user => !user.deleted);
				setUsers(allUsers);
				setLoading(false);
			})();
		}

		return () => {
			active = false;
		};
	}, []);

	return [users, isLoading];
};
