import { useState, useCallback } from 'react';
import { deleteUser } from '../helpers/api';

export default (opts = {}) => {
	const [inRemoving, setInRemoving] = useState(false);
	const [error, setError] = useState(null);

	const deleteUserRequest = useCallback(async (id) => {
		if (id) {
			setInRemoving(true);

			try {
				await deleteUser(id);
				if (typeof opts.onSuccess === 'function') {
					opts.onSuccess();
				}
			} catch (err) {
				setError(err);
			} finally {
				setInRemoving(false);
			}
		}
	}, []);

	return [deleteUserRequest, inRemoving, error];
};
