import styled from 'styled-components';
import { Button, Dialog, Input, IconButton } from '@material-ui/core';
import IconSearch from '@material-ui/icons/Search';

export const Header = styled.section`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: flex-end;
	margin-top: 50px;
	margin-bottom: 34px;
`;

export const SearchInput = styled(Input)`
	border: solid 2px #ffffff;
	border-radius: 50px;
	height: 50px;
	margin-bottom: 40px;
	color: white;
`;

export const AddButton = styled(Button)`
	height: 50px;
	border-radius: 50px;
	background: white;
	margin: 0;
	padding-left: 26px;
	padding-right: 26px;
	text-transform: none;
	font-family: Lato;
	font-size: 17px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.24;
	letter-spacing: normal;
	text-align: left;
	color: #00b2e7;
	white-space: nowrap;
`;

export const SearchIcon = styled(IconSearch)`
	color: #f1efef;
	margin-left: 15px;
	margin-right: 10px;
`;

export const ScrollWrapper = styled.div`
	overflow-x: hidden;
	padding-right: 5px;
	margin-bottom: 15px;
`;

export const Title = styled.h3`
	font-family: Lato;
	font-size: 35px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
`;

export const LoadWrapper = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const EditDialog = styled(Dialog)`
	.paper {
		${({ theme }) => `
			width: 100%;
			height: auto;		
  		${theme.breakpoints.down('md')} {
    		margin: 0;
  		}
  	`}
	}
`;

export const CloseButton = styled(IconButton)`
	position: absolute;
	padding: 0;
	right: 25px;
	top: 26px;
`;
