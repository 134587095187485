import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Drawer, useMediaQuery } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import userIcon from '../../assets/images/user-icon.svg';
import pencilIcon from '../../assets/images/pencil.svg';
import envelopeIcon from '../../assets/images/envelope.svg';
import * as css from './NavBar.module.scss';

const NavBar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const mediaQ = useMediaQuery('(max-width:850px)' /* cover until Pixel 2X */);
	const handelOnOpen = () => {
		setIsOpen(true);
	};
	const toggleSidebar = (event, _open) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setIsOpen(_open);
	};
	const sideBar = (
		<div
			onKeyDown={e => toggleSidebar(e, false)}
			onClick={e => toggleSidebar(e, false)}
			className={css.sidebar}
		>
			<Button className={css.navBtn}>
				<NavLink to="/questions" className={css.link}>
					<img src={userIcon} alt="user" className={css.icon} />
					<span className={`${css.shared} `}>My </span>
					<span className={`${css.shared} `}>Questions</span>
				</NavLink>
			</Button>

			<Button className={css.navBtn}>
				<NavLink to="/post-question" className={css.link}>
					<img src={pencilIcon} alt="pencil" className={css.icon} />
					<span className={`${css.shared} `}>Post </span>
					<span className={`${css.shared} `}>a Question</span>
				</NavLink>
			</Button>

			<Button className={css.navBtn}>
				<NavLink to="/responses" className={css.link}>
					<img src={envelopeIcon} alt="envelope" className={css.icon} />
					<span className={`${css.shared}`}>My </span>
					<span className={`${css.shared}`}>Responses</span>
				</NavLink>
			</Button>
		</div>
	);
	return (
		<div data-testid="container">
			{mediaQ ? (
				<>
					<Button onClick={handelOnOpen} className={css.iconContainer}>
						<MenuIcon />
					</Button>

					<Drawer open={isOpen} onClose={e => toggleSidebar(e, false)}>
						{sideBar}
					</Drawer>
				</>
			) : (
				<div className={css.navContainer}>
					<Button className={css.navBtn}>
						<NavLink
							data-testid="question-link"
							to="/questions"
							className={css.link}
						>
							<img src={userIcon} alt="user" className={css.icon} />
							<span className={`${css.shared} ${css.small}`}>My </span>
							<span className={`${css.shared} ${css.big}`}>Questions</span>
						</NavLink>
					</Button>

					<Button className={css.navBtn}>
						<NavLink
							data-testid="post-question-link"
							to="/post-question"
							className={css.link}
						>
							<img src={pencilIcon} alt="user" className={css.icon} />
							<span className={`${css.shared} ${css.small}`}>Post </span>
							<span className={`${css.shared} ${css.big}`}>a Question</span>
						</NavLink>
					</Button>

					<Button className={css.navBtn}>
						<NavLink
							data-testid="responses-link"
							to="/responses"
							className={css.link}
						>
							<img src={envelopeIcon} alt="envelope" className={css.icon} />
							<span className={`${css.shared} ${css.small}`}>My </span>
							<span className={`${css.shared} ${css.big}`}>Responses</span>
						</NavLink>
					</Button>
				</div>
			)}
		</div>
	);
};

export default NavBar;
