import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './PageShell.module.scss';

const PageShell = Page => {
	return props => {
		const { match } = props;
		return (
			<div className="page">
				<ReactCSSTransitionGroup
					transitionAppear
					transitionAppearTimeout={600}
					transitionEnterTimeout={600}
					transitionLeaveTimeout={600}
					transitionName={match.path === '/' ? 'SlideIn' : 'SlideOut'}
					component="div"
					className="page-wrapper"
				>
					<Page {...props} />
				</ReactCSSTransitionGroup>
			</div>
		);
	};
};

export default PageShell;
