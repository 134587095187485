import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { GlobalContext } from '../context/Context';
import { checkRole } from '../helpers/authHelper';
import { knownRoles } from '../constants/constants';

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
	const [auth] = useContext(GlobalContext);
	const isAuth = auth;

	return (
		<Route
			{...rest}
			render={props => {
				const component = <Component {...props} />;

				if (isAuth === null) {
					return <div className="loader" />;
				}
				const { RECIPIENT } = knownRoles;

				let { role } = auth;
				const isAnonymous = !!auth.anonymous;

				if (!knownRoles[role]) {
					role = RECIPIENT.id;
				}

				if (
					isAuth === 'signed-out' ||
					isAnonymous ||
					(isAuth && !checkRole(role, roles))
				) {
					return rest.path === '/' ? component : <Redirect to="/" />;
				}

				const url =
					(knownRoles[role] && knownRoles[role].redirectTo) || '/post-question';
				return rest.path === '/' ? <Redirect to={url} /> : component;
			}}
		/>
	);
};

export default ProtectedRoute;
