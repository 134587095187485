import * as firebase from 'firebase';

export default function(questionId, answerId) {
	if (!questionId || !answerId) {
		return Promise.reject(new Error('Please provide questionId & answerId'));
	}

	const db = firebase.firestore();
	return db
		.collection('questions')
		.doc(questionId)
		.collection('answers')
		.doc(answerId)
		.get();
}
