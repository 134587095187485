/* eslint-disable no-unused-expressions,jsx-a11y/anchor-is-valid */
import React, { useCallback, useRef, useState } from 'react';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	InputAdornment,
	TextField
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import GdprDialog from '../GdprPage/GdprDialog/GdprDialog';

import useResetPassword from '../../hooks/useResetPassword';
import * as Style from '../login/loginForm/LoginForm.module.scss';
import * as SC from './partials';
import { requiredFieldError } from '../../constants/constants';

const ResetPassword = props => {
	const { history, setMode, token } = props;

	const { getValues, register, handleSubmit, errors } = useForm();
	const [resetPassword, inLoading] = useResetPassword({
		onSuccess: () => history.replace('/')
	});
	const [gdprIsOpen, setGdprIsOpen] = useState(false);
	const gdprCheckbox = useRef();

	const onSubmit = ({ password }) => {
		resetPassword({
			password,
			token
		});
	};

	const handleGdprAcceptClick = useCallback(() => {
		setGdprIsOpen(false);
		if (!getValues('gdprChecked')) {
			gdprCheckbox.current?.click?.();
		}
	}, []);

	const handleGdprDeclineClick = useCallback(() => {
		setGdprIsOpen(false);
		if (getValues('gdprChecked')) {
			gdprCheckbox.current?.click?.();
		}
	}, []);

	const handlePravicyPolicyClick = useCallback(ev => {
		ev.preventDefault();
		ev.stopPropagation();

		setGdprIsOpen(true);
	}, []);

	const gdprLabel = (
		<span>
			<span>I have read and agree to the Moving Motivators</span>
			<a href="#" onClick={handlePravicyPolicyClick}>
				Pravicy Policy
			</a>
		</span>
	);

	const passErrMsg = errors.password?.message;
	const confirmPassErrMsg = errors.confirmPassword?.message;
	const gdprErrMsg = errors.gdprChecked?.message;

	return (
		<form
			className={Style.formWrapper}
			onSubmit={handleSubmit(onSubmit)}
			autoComplete="off"
		>
			<p className={Style.loginTitle}>
				{setMode ? 'Set your Password' : 'Resetting your Password'}
			</p>
			<div className={Style.inputWrapper}>
				<TextField
					error={passErrMsg}
					className={Style.inputText}
					placeholder="Password"
					type="password"
					name="password"
					autoComplete="off"
					aria-describedby="password-error-text"
					variant="outlined"
					inputRef={register({
						required: requiredFieldError,
						minLength: {
							value: 6,
							message: 'Password length must be more than 6 characters'
						}
					})}
					InputProps={{
						'data-testid': 'password-new',
						style: {
							borderRadius: '35px',
							borderColor: '#919191'
						},
						startAdornment: (
							<InputAdornment position="start">
								<VpnKeyIcon className={Style.keyIcon} />
							</InputAdornment>
						)
					}}
				/>
				{passErrMsg && (
					<SC.HelperText data-testid="password-error-message">
						{passErrMsg}
					</SC.HelperText>
				)}
			</div>
			<div className={Style.inputWrapper}>
				<TextField
					error={confirmPassErrMsg}
					className={Style.inputText}
					placeholder="Confirm Password"
					type="password"
					name="confirmPassword"
					autoComplete="off"
					aria-describedby="password-error-text"
					variant="outlined"
					inputRef={register({
						required: requiredFieldError,
						validate: d => d === getValues('password') || 'Passwords must match'
					})}
					InputProps={{
						'data-testid': 'password-new-confirm',
						style: {
							borderRadius: '35px',
							borderColor: '#919191'
						},
						startAdornment: (
							<InputAdornment position="start">
								<VpnKeyIcon className={Style.keyIcon} />
							</InputAdornment>
						)
					}}
				/>
				{confirmPassErrMsg && (
					<SC.HelperText data-testid="confirm-password-error-message">
						{confirmPassErrMsg}
					</SC.HelperText>
				)}
			</div>
			{setMode && (
				<SC.ControlLabelWrapper>
					<FormControlLabel
						value
						control={<Checkbox innerRef={gdprCheckbox} />}
						label={gdprLabel}
						name="gdprChecked"
						inputRef={register({
							required:
								'Please accept the Moving Motivators Privacy Policy to create your account'
						})}
					/>
					{gdprErrMsg && (
						<SC.HelperText data-testid="confirm-password-error-message">
							{gdprErrMsg}
						</SC.HelperText>
					)}
					<GdprDialog
						open={gdprIsOpen}
						onAccept={handleGdprAcceptClick}
						onDecline={handleGdprDeclineClick}
					/>
				</SC.ControlLabelWrapper>
			)}
			<div className={Style.buttonWrapper}>
				<Button
					type="submit"
					data-testid="set_password-button"
					className={Style.button}
					variant="contained"
					disabled={inLoading}
				>
					{setMode ? 'Set Password' : 'Reset Passwor'}
				</Button>
				{inLoading && (
					<CircularProgress
						data-testid="loading-button"
						size={24}
						className={Style.buttonProgress}
					/>
				)}
			</div>
		</form>
	);
};

export default ResetPassword;
