export const sortFunc = (a, b) => {
	if (a < b) return -1;
	if (a > b) return 1;
	return 0;
};

export const reverseSortFunc = (a, b) => {
	if (a > b) return -1;
	if (a < b) return 1;
	return 0;
};

export const getSortedByStringField = (arr, fieldName, reverse) => {
	// array of objects; field to sorting must be typeof string
	return arr.sort((a, b) => {
		const aName = a[fieldName]?.toLowerCase();
		const bName = b[fieldName]?.toLowerCase();

		if (reverse) {
			return reverseSortFunc(aName, bName);
		}

		return sortFunc(aName, bName);
	});
};

export const isBottom = el => {
	const elTop = el.scrollTop;
	const elHeight = el.scrollHeight;
	const elOffset = el.offsetHeight;

	return elTop > elHeight - elOffset - 1;
};

export const dateBaseOrder = (itemA, itemB) => {
	if (!itemA || !itemB || !itemA.date || !itemB.date) {
		return 0;
	}
	if (itemA.date > itemB.date) {
		return -1;
	}
	if (itemA.date < itemB.date) {
		return 1;
	}
	return 0;
};
