import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const DialogContent = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 30px;
	
	& > span {
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 5px;
	}
	
	& > p {
		text-align: center;
		font-size: 14px;
		line-height: 17px;
		margin: 0;
	}
`;

export const DialogIcon = styled.div`
	width: 103px;
	height: 103px;
	display: flex;
	border: solid 3px #dd5c5c;
	border-radius: 50%;
	flex: 1 1 auto;
	justify-content: center;
	align-items: center;
	font-size: 40px;
	color: #dd5c5c;
	margin-bottom: 17px;
`;

export const DialogControls = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 30px;
`;

export const CancelButton = styled(Button)`
	height: 50px;
	border-radius: 25px;
	border: solid 2px #505050;
	text-transform: none;
	padding: 0 55px;
`;

export const AgreeButton = styled(Button)`
	height: 50px;
	border-radius: 25px;
	background-color: #dd5c5c;
	color: #ffffff;
	text-transform: none;
	padding: 0 55px;
`;
