import styled from 'styled-components';
import { FormHelperText } from '@material-ui/core';

export const ControlLabelWrapper = styled.div`
	margin-top: -10px;

	.MuiSvgIcon-root {
		color: #00b2e7;
	}

	.MuiFormControlLabel-label {
		a {
			margin-left: 5px;
			color: #00b2e7;
		}
	}
`;

export const HelperText = styled(FormHelperText)`
	color: #b00020;
	margin-left: 15px;
`;
