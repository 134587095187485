export const oldPassError =
	'The password you provided, does not match your current password';
export const notValidLengthPassError =
	'Password length must be 6 or more characters';
export const confirmPassError = 'Password does not match your new password';
export const requiredFieldError = 'Field is Required';
export const emailFieldError =
	'Email is in wrong format (e.g. xxxxx@xxxxx.xxxx)';

export const knownRoles = {
	SUPER_ADMIN: {
		id: 'SUPER_ADMIN',
		title: 'Super Admin',
		redirectTo: '/post-question'
	},
	ADMIN: {
		id: 'ADMIN',
		title: 'Administrator',
		redirectTo: '/post-question'
	},
	INTERVIEWER: {
		id: 'INTERVIEWER',
		title: 'Interviewer',
		redirectTo: '/post-question'
	},
	FACILITATOR: {
		id: 'FACILITATOR',
		title: 'Facilitator',
		redirectTo: '/post-question'
	},
	RECIPIENT: {
		id: 'RECIPIENT',
		title: 'Recipient',
		redirectTo: '/responses'
	}
};

export const getPossibleRoles = () => {
	const { SUPER_ADMIN, ...rest } = knownRoles;
	return rest;
};

export const emailRegEx = RegExp(
	'^\\w+([.-]?\\w+)*@\\w+([.-]?\\w+)*(\\.\\w{2,3})+$'
);

export const RECIPIENTS_TYPES = {
	GUEST: 'GUEST',
	USERS: 'USERS'
};
