/* eslint-disable no-unused-expressions,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,consistent-return */
import React, { useCallback, useContext, useRef, useState } from 'react';
import {
	Avatar,
	Typography,
	Dialog,
	TextField,
	DialogContent,
	DialogActions,
	DialogTitle,
	Button,
	Icon
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import { getOrientation } from 'get-orientation/browser';
import * as firebase from 'firebase';
import { GlobalContext } from '../../context/Context';
import * as Style from './Profile.module.scss';
import {
	changeAvatar,
	changePassword,
	removeAvatar
} from '../../helpers/authHelper';
import readFile from '../../helpers/readFile';
import ImageCrop from './ImageCrop';
import { getCroppedImg, getRotatedImage } from '../../helpers/imageHelper';
import useInput from '../../hooks/useInput';
import {
	confirmPassError,
	notValidLengthPassError,
	oldPassError,
	requiredFieldError
} from '../../constants/constants';

const ORIENTATION_TO_ANGLE = {
	'3': 180,
	'6': 90,
	'8': -90
};

const useStyles = makeStyles(theme => {
	return {
		editProfileWrapper: {
			display: 'flex',
			width: '100%',
			justifyContent: 'space-between'
		},
		paper: {
			width: '100%',
			height: 'auto',
			[theme.breakpoints.down('md')]: {
				margin: '0'
			}
		},

		usernameTypography: {
			fontFamily: 'Montserrat',
			fontSize: '20px',
			fontWeight: 'bold',
			lineHeight: '24px',
			color: '#505050',
			marginBottom: '16px',

			[theme.breakpoints.down('sm')]: {
				marginTop: '3.25rem',
				fontSize: '1rem'
			}
		},

		iconRoot: {
			width: '100%',
			height: '103px',
			textAlign: 'center',
			marginBottom: '13px',
			overflow: 'visible'
		},
		defaultProfileAvatar: {
			position: 'relative',
			left: '-5%',
			width: '110%',
			height: '110%'
		},
		textField: {
			width: '100%',
			marginBottom: '0.5rem',
			padding: '0.5rem'
		},
		textFieldInputLabel: {
			padding: '0.1rem',
			margin: '0.5rem'
		},
		textFieldInputRoot: {
			borderRadius: '1.5625rem'
		},
		dialogActionRoot: {
			width: '100%',
			paddingBottom: '22px'
		},
		saveChangesBtn: {
			width: ' 100%',
			margin: '0 auto',
			borderRadius: '1.5625rem',
			padding: '0.625rem'
		},
		removeAvatarBtn: {
			border: '1px solid #f02a2a',
			color: '#f02a2a',
			background: 'none',
			margin: '0 auto',
			borderRadius: '1.5625rem',
			padding: '9px 29px'
		},
		avatarWrapper: {
			display: 'inline-block',
			position: 'relative',
			width: '103px',
			height: '103px'
		},
		avatarEditBtn: {
			color: 'white',
			top: '-5px',
			right: '-10px',
			lineHeight: '32px',
			position: 'absolute',
			fontSize: '52px',
			cursor: 'pointer'
		},
		avatar: {
			width: '103px',
			height: '103px'
		}
	};
});

export default ({ open, closeProfile }) => {
	const classes = useStyles();
	const [auth, setAuth] = useContext(GlobalContext);

	const fileUploadRef = useRef(null);
	const isChangedPasswords = useRef({ changed: false });
	const isChangedAvatar = useRef({ changed: false });

	const [oldPassMessage, setOldPassMessage] = useState('');
	const [newPassMessage, setNewPassMessage] = useState('');
	const [confirmPassMessage, setConfirmPassMessage] = useState('');
	const [imageSrc, setImageSrc] = useState(null);
	const [imageInputKey, setImageInputKey] = useState(new Date());
	const [avatar, setAvatar] = useState(auth.avatar);

	const [input, setInput, clearAllInputValues] = useInput();

	const isValidPassword = (password = '') => password.length >= 6;

	const errorFieldsStyles = {
		color: '#b00020',
		alignSelf: 'flex-start',
		margin: '-0.5rem 0 10px 0',
		paddingLeft: '20px'
	};

	const cleanAllErrorMessages = () => {
		setOldPassMessage('');
		setNewPassMessage('');
		setConfirmPassMessage('');

		return false;
	};
	const validateFields = ({ oldPass, newPass, confirmPass }) => {
		let valid = true;

		if (!oldPass && !newPass && !confirmPass) {
			isChangedPasswords.current.changed = false;
			setOldPassMessage(requiredFieldError);
			setNewPassMessage(requiredFieldError);
			setConfirmPassMessage(requiredFieldError);
			return false;
		}

		isChangedPasswords.current.changed = true;

		const isOldPassValid = isValidPassword(oldPass);
		const isNewPassValid = isValidPassword(newPass);
		const isConfirmPassValid = isValidPassword(confirmPass);

		if (!oldPass) {
			setOldPassMessage(requiredFieldError);
			valid = false;
		} else if (!isOldPassValid) {
			setOldPassMessage(notValidLengthPassError);
			valid = false;
		} else if (isOldPassValid && oldPassMessage) {
			setOldPassMessage('');
			valid = true;
		}
		if (!newPass) {
			setNewPassMessage(requiredFieldError);
			valid = false;
		} else if (!isNewPassValid) {
			setNewPassMessage(notValidLengthPassError);
			valid = false;
		} else if (isNewPassValid && newPassMessage) {
			setNewPassMessage('');
			valid = isOldPassValid;
		}
		if (!confirmPass) {
			setConfirmPassMessage(requiredFieldError);
			valid = false;
		} else if (!isConfirmPassValid) {
			setConfirmPassMessage(notValidLengthPassError);
			valid = false;
		}
		if (confirmPass && isConfirmPassValid && confirmPass !== newPass) {
			setConfirmPassMessage(confirmPassError);
			valid = false;
		} else if (
			confirmPassMessage &&
			isNewPassValid &&
			isConfirmPassValid &&
			confirmPass === newPass
		) {
			setConfirmPassMessage('');
			valid = isOldPassValid;
		}

		return valid;
	};

	const saveHandler = e => {
		e.preventDefault();
		const oldPass = input.oldPassword;
		const newPass = input.newPassword;
		const confirmPass = input.confirmPassword;
		const shouldUpdatePassword = validateFields({
			oldPass,
			newPass,
			confirmPass
		});
		const currentUserAvatar = firebase.auth().currentUser.photoURL;
		const promices = [];

		if (shouldUpdatePassword) {
			promices.push(changePassword(oldPass, newPass));
		}
		if (
			isChangedAvatar.current.changed &&
			(!isChangedPasswords.current.changed || shouldUpdatePassword)
		) {
			if (avatar) {
				promices.push(changeAvatar(avatar));
			} else if (currentUserAvatar) {
				promices.push(removeAvatar());
			}
		}

		if (
			!promices.length ||
			(isChangedPasswords.current.changed && !shouldUpdatePassword)
		) {
			return validateFields({
				oldPass,
				newPass,
				confirmPass
			});
		}

		cleanAllErrorMessages();

		Promise.all(promices)
			.then(() => {
				if (avatar) {
					setAuth({
						...auth,
						avatar
					});
				} else if (currentUserAvatar) {
					setAuth({
						...auth,
						avatar: null
					});
				}

				isChangedAvatar.current.changed = false;
				clearAllInputValues();
				closeProfile();
			})
			.catch(() => {
				if (shouldUpdatePassword) {
					setOldPassMessage(oldPassError);
				}
			});
	};
	const handleRemoveAvatar = () => {
		isChangedAvatar.current.changed = true;
		setAvatar(null);
	};
	const handleUploadFileClick = e => {
		e.preventDefault();

		fileUploadRef.current && fileUploadRef.current.click(e);
	};
	const fileChangeHandler = async e => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			let imageDataUrl = await readFile(file);

			// apply rotation if needed
			const orientation = await getOrientation(file);
			const rotation = ORIENTATION_TO_ANGLE[orientation];

			if (rotation) {
				imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
			}

			setImageSrc(imageDataUrl);
		}
	};
	const resetInputForFiles = () => {
		setImageInputKey(new Date());
	};
	const handleCloseDialog = () => {
		setImageSrc(null);
		setAvatar(auth.avatar);
		isChangedAvatar.current.changed = false;

		resetInputForFiles();
		cleanAllErrorMessages();
		clearAllInputValues();

		closeProfile();
	};
	const handleApply = useCallback(
		async croppedAreaPixels => {
			const avatarSrc = await getCroppedImg(imageSrc, croppedAreaPixels);
			isChangedAvatar.current.changed = true;
			setAvatar(avatarSrc);
			resetInputForFiles();
			setImageSrc(null);
		},
		[imageSrc]
	);
	const handleCancel = useCallback(() => {
		resetInputForFiles();
		setImageSrc(null);
	}, []);

	return (
		<div>
			<input
				type="file"
				onChange={fileChangeHandler}
				style={{ display: 'none' }}
				ref={fileUploadRef}
				key={imageInputKey}
				accept="image/*"
			/>
			<Dialog
				classes={{ paper: classes.paper }}
				onClose={handleCloseDialog}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<DialogTitle className={classes.editProfileWrapper} disableTypography>
					<h4 className={Style.edit_profile_title}>Editing My Profile</h4>
					<IconButton
						aria-label="Close"
						className={classes.closeButton}
						onClick={handleCloseDialog}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				{!imageSrc && (
					<DialogContent className={Style.edit_profile_content}>
						<Typography className={classes.usernameTypography} variant="h5">
							{auth && `${auth.firstName} ${auth.lastName}`}
						</Typography>
						<Icon className={classes.iconRoot} fontSize="large">
							<span className={classes.avatarWrapper}>
								{!avatar && (
									<AccountCircle
										htmlColor="#ccc"
										className={classes.defaultProfileAvatar}
										fontSize="3rem"
									/>
								)}
								{!!avatar && (
									<Avatar
										alt="avatar"
										src={avatar}
										className={classes.avatar}
									/>
								)}
								<span
									className={classes.avatarEditBtn}
									onClick={handleUploadFileClick}
								>
									<SvgIcon viewBox="0 0 51 51" color="white" fontSize="inherit">
										<g id="prefix__Group_1188" data-name="Group 1188">
											<g
												id="prefix__Group_1061"
												data-name="Group 1061"
												transform="rotate(45 12.828 30.968)"
											>
												<circle
													id="prefix__Ellipse_129"
													cx="18.141"
													cy="18.141"
													r="18.141"
													data-name="Ellipse 129"
													style={{ fill: '#04f795' }}
												/>
											</g>
											<g
												id="prefix__Group_1256"
												data-name="Group 1256"
												transform="translate(-77.628 -221.396)"
											>
												<path
													id="prefix__Path_168"
													d="M101.058 252.095l-3.229.757.757-3.228 7.791-7.792a1 1 0 0 1 1.413 0l1.06 1.06a1 1 0 0 1 0 1.413z"
													className="prefix__cls-2"
													data-name="Path 168"
												/>
												<path
													id="prefix__Line_50"
													d="M2.474 2.473L0 0"
													className="prefix__cls-2"
													data-name="Line 50"
													transform="translate(106.024 242.184)"
												/>
												<path
													id="prefix__Line_51"
													d="M2.467 2.48L0 0"
													className="prefix__cls-2"
													data-name="Line 51"
													transform="translate(105.318 242.891)"
												/>
												<path
													id="prefix__Line_52"
													d="M2.472 2.472L0 0"
													className="prefix__cls-2"
													data-name="Line 52"
													transform="translate(98.712 249.497)"
												/>
											</g>
										</g>
									</SvgIcon>
								</span>
							</span>
						</Icon>
						<Button
							className={classes.removeAvatarBtn}
							onClick={handleRemoveAvatar}
						>
							Remove
						</Button>
						<div className={Style.password_change_container}>
							<h5>Changing Password</h5>
							<TextField
								name="oldPassword"
								className={Style.password_change_input}
								classes={{ root: classes.textField }}
								type="password"
								label="Old Password"
								variant="outlined"
								autoComplete="off"
								onChange={setInput}
								value={input.oldPassword}
							/>
							{!!oldPassMessage && (
								<FormHelperText
									data-testid="email-error-message"
									style={errorFieldsStyles}
								>
									{oldPassMessage}
								</FormHelperText>
							)}
							<TextField
								name="newPassword"
								className={Style.password_change_input}
								classes={{ root: classes.textField }}
								type="password"
								label="New Password"
								variant="outlined"
								onChange={setInput}
								value={input.newPassword}
							/>
							{!!newPassMessage && (
								<FormHelperText
									data-testid="email-error-message"
									style={errorFieldsStyles}
								>
									{newPassMessage}
								</FormHelperText>
							)}
							<TextField
								name="confirmPassword"
								className={Style.password_change_input}
								classes={{ root: classes.textField }}
								type="password"
								label="Confirm New Password"
								variant="outlined"
								onChange={setInput}
								value={input.confirmPassword}
							/>
							{!!confirmPassMessage && (
								<FormHelperText
									data-testid="email-error-message"
									style={errorFieldsStyles}
								>
									{confirmPassMessage}
								</FormHelperText>
							)}
						</div>
						<DialogActions className={classes.dialogActionRoot}>
							<Button className={classes.saveChangesBtn} onClick={saveHandler}>
								Save changes
							</Button>
						</DialogActions>
					</DialogContent>
				)}
				{!!imageSrc && (
					<DialogContent className={Style.edit_profile_content}>
						<ImageCrop
							imageSrc={imageSrc}
							handleApply={handleApply}
							handleCancel={handleCancel}
						/>
					</DialogContent>
				)}
			</Dialog>
		</div>
	);
};
