/* eslint-disable consistent-return */
import { useState } from 'react';

export default function() {
	const [input, setInput] = useState({});
	const setInputValue = (name, value) => {
		if (typeof name === 'string') {
			return setInput({
				...input,
				[name]: value
			});
		}

		if (name && Object.keys(name).length) {
			setInput({
				...input,
				...name
			});
		}
	};
	const handleInputChange = e =>
		setInputValue(e.currentTarget.name, e.currentTarget.value);
	const clearAllInputs = () => setInput({});

	return [input, handleInputChange, clearAllInputs, setInputValue];
}
