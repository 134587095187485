import styled from 'styled-components';
import { Divider, Typography, Link } from '@material-ui/core';

export const StyledFooter = styled.footer`
	height: 80px;
	width: 100%;
	z-index: 100;
	background: rgba(255, 255, 255, 0.1);
`;

export const StyledDivider = styled(Divider)`
	background-color: rgba(255, 255, 255, 0.1);
	height: 2px;
	margin-top: -1px;
`;
export const StyledContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

export const StyledTypography = styled(Typography)`
	color: ${props => props.color};
	font-family: Lato, Roboto, Arial, sans-serif;
	font-size: 14px;
	font-weight: 800;
	line-height: 17px;
`;

export const StyledLink = styled(Link)`
	color: ${props => props.color};
	font-family: Lato, Roboto, Arial, sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	text-decoration: underline;
	padding-top: 3px;

	&:link,
	&:visited {
		color: ${props => props.color};
		text-decoration: underline;
	}
`;
