import React from 'react';
import Moment from 'react-moment';
import './QuestionRow.module.scss';
import { makeStyles, styled } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useMediaQuery } from '@material-ui/core';

const useLinearProgressStyles = makeStyles(theme => ({
	root: {
		height: 5,
		borderRadius: 20,
		margin: theme.spacing(1),
		backgroundColor: theme.palette.common.white
	},
	bar: {
		backgroundColor: theme.palette.secondary.main
	}
}));

const QuestionRow = ({
	text,
	responded,
	recipientsCount,
	notResponded,
	date
}) => {
	const progressClasses = useLinearProgressStyles();
	const mediaQ = useMediaQuery('(max-width:768px)');
	const ProgressWrapper = styled('div')({
		width: mediaQ ? 50 : 70,
		minWidth: mediaQ ? 50 : 70
	});

	return (
		<div className="list">
			<div className="list__meta">
				<ProgressWrapper>
					<LinearProgress
						classes={progressClasses}
						variant="determinate"
						color="secondary"
						value={recipientsCount ? (responded * 100) / recipientsCount : 0}
					/>
				</ProgressWrapper>
				<p className="list__meta--title" data-testid="response">
					{text}
					<span className="list__meta--date" data-testid="date">
						<Moment format="DD MMM YYYY">{date}</Moment>
					</span>
				</p>
			</div>
			<div className="list__questions-state">
				<div
					className="list__questions-state--responded"
					data-testid="responded"
				>
					{responded}
				</div>
				<div
					className="list__questions-state--non-responded"
					data-testid="not-responded"
				>
					{notResponded}
				</div>
			</div>
		</div>
	);
};

export default QuestionRow;
