import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

const AlertComponent = ({
	setHideMessage,
	classes,
	_className,
	variant,
	elevation,
	severityIndicator,
	message
}) => {
	return (
		<Alert
			classes={classes}
			className={_className}
			variant={variant}
			elevation={elevation}
			severity={severityIndicator}
			action={
				<IconButton
					aria-label="close"
					color="inherit"
					size="small"
					onClick={() => {
						setHideMessage(true);
					}}
				>
					<CloseIcon fontSize="inherit" />
				</IconButton>
			}
		>
			{message}
		</Alert>
	);
};

export default AlertComponent;
