import styled from 'styled-components';
import {
	Avatar,
	Button,
	MenuItem,
	FormControl,
	FormLabel,
	TextField
} from '@material-ui/core';

export const Wrapper = styled.form`
	padding: 25px 30px 40px;
`;

export const Headline = styled.h4`
	margin: 0;
	font-size: 20px;
	color: #505050;
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 24px;

	@media only screen and(max-width: 481px) {
		font-size: 1.1rem;
	}
`;

export const AvatarWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 10px;
`;

export const UserAvatar = styled(Avatar)`
	width: 103px;
	height: 103px;
`;

export const DelImageButton = styled(Button)`
	border-radius: 25px;
	color: #f02a2a;
	border-color: #f02a2a;
	text-transform: none;
	padding-left: 30px;
	padding-right: 30px;
	margin-top: 10px;
`;

export const StyledTextField = styled(TextField)`
	margin-top: 16px;

	.MuiInputBase-root {
		border-radius: 25px;
		border-color: #ededed;
		height: 50px;

		&.Mui-disabled {
			background-color: #ededed;
		}
	}
`;

export const RuleLabel = styled(FormLabel)`
	font-size: 14px;
	color: #505050;
	font-weight: bold;
	display: flex;
	margin: 16px 0 8px 9px;
`;

export const RoleSelect = styled(FormControl)`
	width: 100%;
	border-radius: 25px;
	height: 50px;

	.MuiInputBase-root,
	.MuiSelect-root {
		box-sizing: border-box;
		height: 50px;
		border-radius: 25px;
	}

	.MuiSelect-root {
		padding-top: 0;
		padding-bottom: 0;
		display: flex;
		align-items: center;
	}
`;

export const StyledItem = styled(MenuItem)`
	font-family: Montserrat, serif;
`;

export const ConfirmCopy = styled.div`
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	font-weight: bold;
	line-height: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 55px 0 20px;

	& > p {
		font-size: 12px;
		line-height: 15px;
		font-weight: normal;
	}
`;

export const ResetBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 35px 0;

	${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  `}
`;

export const ResetCopy = styled.div`
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	font-weight: bold;
	line-height: 15px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-right: 23px;

	& > p {
		font-size: 12px;
		line-height: 15px;
		font-weight: normal;
		text-align: start;
	}

	${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      padding-right: 0;
			align-items: center;
      
	    & > p {
				text-align: center;
			}
    }
  `}
`;

export const ResetButton = styled(Button)`
	border-radius: 25px;

	.MuiButton-label {
		margin: 0 20px;
		text-transform: none;
		white-space: nowrap;
	}
`;

export const SubmitButton = styled(Button)`
	width: 100%;
	margin: 0;
	height: 50px;
	box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.09);
	border-radius: 25px;

	.MuiButton-label {
		font-family: Lato, serif;
		font-size: 17px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.24;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
		text-transform: none;
	}
`;
