/* eslint-disable camelcase */
// @flow
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import {
	fas,
	faArrowAltCircleDown,
	faArrowAltCircleUp,
	faInfoCircle,
	faSortAmountDownAlt
} from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import * as firebase from 'firebase/app';
import classnames from 'classnames';
import NavBar from './components/NavBar/NavBar';
import FourOhFour from './containers/FourOhFour/404';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import * as CSS from './App.module.scss';
import Login from './containers/Login/Login';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import GdprPage from './containers/GdprPage/GdprPage';
import NewQuestion from './containers/NewQuestion/NewQuestion';
import PageShell from './containers/PageShell/PageShell';
import { GlobalContext, CardProvider } from './context/Context';
import ProtectedRoute from './HOC/ProtectedRoute';
import AnonymousRoute from './HOC/AnonymousRoute';
import MyQuestions from './containers/MyQuestions/MyQuestions';
import Question from './containers/Question/Question';
import UserManagement from './containers/UserManagement/UserManagement';
import MyResponses from './containers/MyResponses/MyResponses';
import QuestionResponseContainer from './containers/QuestionResponse/QuestionResponseContainer';
import useSetAppTheme from './hooks/useSetAppTheme';
import ViewResponse from './containers/ViewResponse/ViewResponse';
import { knownRoles } from './constants/constants';

library.add(
	far,
	fas,
	faSortAmountDownAlt,
	faArrowAltCircleDown,
	faArrowAltCircleUp,
	faInfoCircle
);

const App = ({ history }) => {
	const [value, setValue] = useState(0);
	const [lightTheme, setLightTheme] = useSetAppTheme(history.location.pathname);
	const handleChange = useCallback(
		(event, newValue) => {
			setValue(newValue);
		},
		[value]
	);
	const [auth, setAuth] = useContext(GlobalContext);
	const db = firebase.firestore();
	const collectionRef = db.collection('users');
	const {
		ADMIN,
		SUPER_ADMIN,
		FACILITATOR,
		INTERVIEWER,
		RECIPIENT
	} = knownRoles;

	useEffect(() => {
		// listener for route changes
		history.listen(evt => {
			setLightTheme(evt.pathname);
		});

		firebase.auth().onAuthStateChanged(async user => {
			if (!user) {
				// User is signed out.
				return setAuth('signed-out');
			}

			if (user.isAnonymous) {
				return setAuth({
					uid: user.uid,
					firstName: 'Anonymous',
					lastName: 'User',
					anonymous: true
				});
			}

			// User is signed in.
			const {
				uid, // 'id' key of document inside users collection
				displayName, // full_name,
				email,
				photoURL
			} = user;

			const authUser = {
				uid,
				displayName,
				email,
				avatar: photoURL,
				firstName: '',
				lastName: '',
				role: 'RECIPIENT',
				subscriptionId: '',
				claims: null
			};

			const userRef = await collectionRef.doc(uid).get();

			if (userRef) {
				const { first_name, last_name, role, subscription_id } =
					userRef.data() || {};

				authUser.firstName = first_name;
				authUser.lastName = last_name;
				authUser.role = role;
				authUser.subscriptionId = subscription_id;
			}
			const idTokenResult = await user.getIdTokenResult();

			if (idTokenResult && idTokenResult.claims) {
				authUser.claims = { ...idTokenResult.claims };
			}

			return setAuth(authUser);
		});
	}, []);

	const isAuth = auth && auth.uid;

	return (
		<div
			data-testid="container"
			className={`${CSS.appContainer}
			 ${lightTheme ? CSS.lightBackground : CSS.darkBackground} ${CSS.allowScroll}`}
		>
			<Header />
			<div
				className={classnames(CSS['content-container'], {
					[CSS['content-container--not-logged-in']]: !isAuth
				})}
			>
				<Switch>
					<ProtectedRoute exact path="/" component={Login} />
					<Route exact path="/reset" component={ResetPassword} />
					<Route exact path="/gdpr" component={PageShell(GdprPage)} />
					<ProtectedRoute
						path="/users-management"
						component={PageShell(UserManagement)}
						roles={[SUPER_ADMIN.id, ADMIN.id]}
					/>
					<>
						{isAuth && lightTheme && (
							<NavBar handleChange={handleChange} value={value} />
						)}
						<ProtectedRoute
							path="/post-question"
							component={PageShell(NewQuestion)}
							roles={[SUPER_ADMIN.id, ADMIN.id, INTERVIEWER.id, FACILITATOR.id]}
						/>
						<ProtectedRoute
							path="/questions"
							exact
							roles={[SUPER_ADMIN.id, ADMIN.id, INTERVIEWER.id, FACILITATOR.id]}
							component={PageShell(MyQuestions)}
						/>
						<ProtectedRoute
							path="/questions/:id"
							component={PageShell(Question)}
							roles={[SUPER_ADMIN.id, ADMIN.id, INTERVIEWER.id, FACILITATOR.id]}
						/>
						<ProtectedRoute
							path="/responses"
							exact
							component={PageShell(MyResponses)}
							roles={[
								SUPER_ADMIN.id,
								ADMIN.id,
								INTERVIEWER.id,
								FACILITATOR.id,
								RECIPIENT.id
							]}
						/>
						<CardProvider>
							<ProtectedRoute
								path="/responses/:questionId"
								exact
								component={PageShell(ViewResponse)}
								roles={[
									SUPER_ADMIN.id,
									ADMIN.id,
									INTERVIEWER.id,
									FACILITATOR.id,
									RECIPIENT.id
								]}
							/>
							<ProtectedRoute
								path="/responses/:id/post-response"
								exact
								component={PageShell(QuestionResponseContainer)}
								roles={[
									SUPER_ADMIN.id,
									ADMIN.id,
									INTERVIEWER.id,
									FACILITATOR.id,
									RECIPIENT.id
								]}
							/>
							<AnonymousRoute
								path="/responses/:id/direct-response"
								exact
								component={PageShell(QuestionResponseContainer)}
							/>
						</CardProvider>
					</>
					<Route path="*" exact component={FourOhFour} />
				</Switch>
				<Footer />
			</div>
		</div>
	);
};

export default withRouter(App);
