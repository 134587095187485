import { useEffect, useState } from 'react';

const useSetAppTheme = path => {
	const [lightTheme, seLightTheme] = useState(true);

	useEffect(() => {
		switch (true) {
			// Todo define the the route that you want to have a dark background
			case path.includes('/post-response'):
				seLightTheme(false);
				break;
			case path.includes('/responses') && path.split('/').length >= 3:
				seLightTheme(false);
				break;
			default:
				seLightTheme(true);
		}
	}, [path]);

	return [lightTheme, seLightTheme];
};

export default useSetAppTheme;
