/* eslint-disable no-await-in-loop */
import { useEffect, useState } from 'react';
import * as firebase from 'firebase/app';

const getImgUrl = async path => {
	const storage = firebase.storage();
	const cardImgRef = storage.ref(path);

	try {
		const url = await cardImgRef.getDownloadURL();

		return url;
	} catch (error) {
		return '';
	}
};

export default () => {
	// declare state
	const [didCancel, setDidCancel] = useState(false);
	const [data, setData] = useState([]);
	const [loadingCards, setLoadingCards] = useState(true);
	const [error, setError] = useState('');
	const [isError, setIsError] = useState(false);

	const db = firebase.firestore();

	const fetchData = async () => {
		setIsError(false);

		let cardsSnapshot;
		const result = [];

		try {
			cardsSnapshot = await db.collection('cards').get();
		} catch (er) {
			if (!didCancel) {
				setIsError(true);
				setError(er.message);
				setLoadingCards(false);
			}
		}

		if (cardsSnapshot && cardsSnapshot.docs) {
			// eslint-disable-next-line no-restricted-syntax
			for (const doc of cardsSnapshot.docs) {
				const {
					name,
					imgs: { desktop, mobile, laptop }
				} = doc.data();

				const imgsUrls = {};

				try {
					if (desktop) {
						imgsUrls.desktop = await getImgUrl(desktop);
					}

					if (mobile) {
						imgsUrls.mobile = await getImgUrl(mobile);
					}

					if (laptop) {
						imgsUrls.laptop = await getImgUrl(laptop);
					}
				} catch (getUrlsError) {
					if (!didCancel) {
						setIsError(true);
						setError(getUrlsError.message);
					}
				}

				result.push({
					id: doc.id,
					impact: 0,
					name,
					imgs: imgsUrls,
					comment: ''
				});
			}
			setLoadingCards(false);
		}

		if (!didCancel) {
			setData(result);
		}
	};

	// TODO we need to convert this useFetch to factory pattern with type to determine which collection will be retrieved
	useEffect(() => {
		setDidCancel(false);
		// handle fetching data request
		fetchData();
		// clean up function
		return () => {
			setDidCancel(true);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return [data, loadingCards, error, isError];
};
