/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { isBottom } from '../../helpers/helpers';
import AlertComponent from '../../components/Alert/Alert';
import useFetch from '../../hooks/useFetch';
import { db } from '../../firebase';
import { GlobalContext } from '../../context/Context';
import ResponseRow from '../../components/ResponseRow/ResponseRow';
import * as Styles from './MyResponses.module.scss';

const useResponses = (initialDataValue, initialPage, initialPageSize) => {
	const [page, setPage] = useState(initialPage);
	const [pageSize] = useState(initialPageSize);
	const [auth] = useContext(GlobalContext);

	const callback = async () => {
		const collectionRef = db.collection('questions');
		const limit = page * pageSize;
		const query = collectionRef
			.where('recipients', 'array-contains-any', [auth.uid])
			.orderBy('date', 'desc')
			.limit(limit);

		const creatorsSet = {};

		const collectIdsAndDocs = async doc => {
			const {
				text,
				date,
				responded = [],
				recipients = [],
				creater_id,
				creater_first_name,
				creater_last_name
			} = doc.data();

			if (creater_id && !creatorsSet[creater_id]) {
				const creatorSnapshot = await db
					.collection('users')
					.doc(creater_id)
					.get();
				creatorsSet[creater_id] = creatorSnapshot.data();
			}

			return {
				questionId: doc.id,
				questionDate: date.toDate(),
				questionText: text,
				questionCreatorId: creater_id,
				questionCreatorAvatar:
					creatorsSet[creater_id] && creatorsSet[creater_id].avatar,
				questionCreatorFirstName: creater_first_name,
				questionCreatorLastName: creater_last_name,
				questionResponded: responded,
				isRespond: !!responded[auth.uid],
				notResponded: recipients.length
					? recipients.length - responded.length
					: 0
			};
		};
		const snapshot = await query.get();
		// eslint-disable-next-line no-return-await
		return await Promise.all(snapshot.docs.map(collectIdsAndDocs));
	};

	const [responses, isLoading, error, isError] = useFetch([], callback, [page]);
	return [responses, isLoading, error, isError, page, setPage];
};

const MyResponses = ({ match }) => {
	const [responses, isLoading, error, isError, page, setPage] = useResponses(
		[],
		1,
		20
	);
	const [hideError, setHideError] = useState(false);
	const [sortedResponses, setSortedResponses] = useState([]);
	const [auth] = useContext(GlobalContext);
	const container = document.getElementsByClassName('infinite-scroll')[0];

	const fetchAnswer = async (questionId, answerId) => {
		const query = await db
			.collection('questions')
			.doc(questionId)
			.collection('answers')
			.doc(answerId);

		const collectIdsAndDoc = doc => {
			// eslint-disable-next-line camelcase
			const { first_name, user, date } = doc.data();
			return {
				answerId,
				respondedFirstName: first_name,
				respondedUserId: user,
				respondDate: date.toDate()
			};
		};
		const snapshot = await query.get();
		return collectIdsAndDoc(snapshot);
	};

	useEffect(() => {
		const promises = [];
		let isSubscribed = true;

		async function fetchData() {
			const data = await Promise.all(promises);
			const respondedQuestion = data.sort(
				(a, b) => new Date(b.respondDate) - new Date(a.respondDate)
			);
			if (isSubscribed) {
				/* Not respond -> Ascending by Question Date - Respond -> Descending by Response Date */
				const notRespondedQuestionAsc = responses
					.filter(response => !response.isRespond)
					.reverse();
				setSortedResponses([...notRespondedQuestionAsc, ...respondedQuestion]);
			}
		}

		if (isSubscribed) {
			responses.forEach(response => {
				if (response.isRespond) {
					const { questionId, questionResponded } = response;
					// eslint-disable-next-line no-restricted-syntax
					for (const [userId, answerId] of Object.entries(questionResponded)) {
						if (userId && answerId && userId === auth.uid) {
							promises.push(
								fetchAnswer(questionId, answerId).then(data => ({
									...data,
									...response
								}))
							);
						}
					}
				}
			});

			fetchData();
		}

		// clean up function
		return () => {
			isSubscribed = false;
		};
	}, [responses.length]);

	return (
		<div className="container">
			<h1 className="title text-center">What Questions did I receive?</h1>
			<div className="scroll-container">
				{isError && !hideError && (
					<AlertComponent
						severityIndicator="error"
						message={error}
						setHideMessage={setHideError}
					/>
				)}
				{!isError && (
					<>
						<div
							onScroll={() => (isBottom(container) ? setPage(page + 1) : null)}
							className="responses infinite-scroll"
						>
							{isLoading && <div className="loader" />}
							{!isLoading && responses.length === 0 ? (
								<h1
									className={`text-center text-white font-weight-medium ${Styles.no_items_text}`}
								>
									no Responses ....
								</h1>
							) : (
								<>
									{sortedResponses.map(response => (
										<ResponseRow
											key={response.id}
											match={match}
											{...response}
										/>
									))}
								</>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default MyResponses;
