import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { GlobalContext } from '../context/Context';
import AnonymousDecorator from './AnonymousDecorator';

const AnonimousRoute = ({ component: Component, ...rest }) => {
	const [auth] = useContext(GlobalContext);

	return (
		<Route
			{...rest}
			render={props => (
				<AnonymousDecorator>
					{auth?.uid ? <Component {...props} /> : <span>In loading</span>}
				</AnonymousDecorator>
			)}
		/>
	);
};

export default AnonimousRoute;
