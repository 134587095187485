import React from 'react';
import { Paper } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import * as SecondStepStyle from '../../../containers/QuestionResponse/QuestionResponseFinalStep.module.scss';
import mostImp from '../../../assets/images/mostImportant.svg';
import leastImp from '../../../assets/images/leastImportant.svg';
import MotivatorCard from '../MotivatorCard/MotivatorCard';

const NeutralDropZone = ({ classes, neutralCards }) => {
	return (
		<Paper
			square
			elevation={0}
			className={`${classes.paper} ${SecondStepStyle.cards_lane}`}
		>
			<div
				className={`${SecondStepStyle.laneIndicator} ${SecondStepStyle.left}`}
			>
				<img src={mostImp} alt="" />
				<p>Least important</p>
			</div>
			<div
				className={`${SecondStepStyle.laneIndicator} ${SecondStepStyle.right}`}
			>
				<img src={leastImp} alt="" />
				<p>Most important</p>
			</div>
			{neutralCards.map((card, index) => {
				return (
					<Draggable
						draggableId={card.id}
						key={card.id}
						index={index}
						isDragDisabled={card.isDragDisabled}
					>
						{(dragProvided, snapshot) =>
							card.imgs ? (
								<div
									{...dragProvided.draggableProps}
									{...dragProvided.dragHandleProps}
									ref={dragProvided.innerRef}
								>
									<MotivatorCard
										title={card.name}
										src={card.imgs.desktop}
										width={60}
										height={70}
										isDragging={snapshot.isDragging}
									/>
								</div>
							) : (
								<div
									className={SecondStepStyle.dummyCard}
									{...dragProvided.draggableProps}
									{...dragProvided.dragHandleProps}
									ref={dragProvided.innerRef}
								/>
							)
						}
					</Draggable>
				);
			})}
		</Paper>
	);
};

export default NeutralDropZone;
