import React, { useState } from 'react';
import useFetchCards from '../hooks/useFetchCards';

const defaultValue = {};
const GlobalContext = React.createContext(defaultValue);
const CardsContext = React.createContext(defaultValue);

const GlobalProvider = ({ children }) => {
	const [auth, setAuth] = useState(null);

	return (
		<GlobalContext.Provider value={[auth, setAuth]}>
			{children}
		</GlobalContext.Provider>
	);
};

const CardProvider = ({ children }) => {
	const [cards, loadingCards] = useFetchCards();
	return (
		<CardsContext.Provider value={[cards, loadingCards]}>
			{children}
		</CardsContext.Provider>
	);
};

export { GlobalProvider, GlobalContext, CardProvider, CardsContext };
