import { useState, useCallback } from 'react';
import { resetPassword } from '../helpers/api';

export default (opts = {}) => {
	const [inLoading, setInLoading] = useState(false);
	const [error, setError] = useState(null);

	const resetPasswordReq = useCallback(async (data) => {
		if (data) {
			setInLoading(true);

			try {
				await resetPassword(data);
				if (typeof opts.onSuccess === 'function') {
					opts.onSuccess();
				}
			} catch (err) {
				setError(err);
			} finally {
				setInLoading(false);
			}
		}
	}, []);

	return [resetPasswordReq, inLoading, error];
};
