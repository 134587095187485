import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import ReviewCards from '../ReviewCards/ReviewCards';
import CommentDialog from '../../QuestionResponse/CommentDialog/CommentDialog';
import AskedQuestion from '../../QuestionResponse/AskedQuestion/AskedQuestion';
import * as Style from './ReviewMyResponse.module.scss';
import img from '../../../assets/images/userPlacholder.png';
import { GlobalContext } from '../../../context/Context';

const ReviewMyResponse = ({
	data,
	question,
	isQuestionLoading,
	cards,
	isLoadingAnswer,
	mobileMediaQuery
}) => {
	const { goBack } = useHistory();
	const [selectedCard, setSelectedCard] = useState(null);
	const [openCommentDialog, setOpenCommentDialog] = useState(false);
	const [auth] = useContext(GlobalContext);
	const handleBackButton = () => {
		goBack();
	};

	const handleCloseDialog = () => {
		setOpenCommentDialog(false);
	};
	const handleCardSelection = card => {
		setSelectedCard(card);
		if (mobileMediaQuery && card.comment) setOpenCommentDialog(true);
	};
	return (
		<>
			{selectedCard && openCommentDialog && (
				<CommentDialog
					card={selectedCard}
					handleClose={handleCloseDialog}
					isEditable={false}
				/>
			)}
			<div className={Style.myResponseContainer}>
				{isLoadingAnswer ? (
					<div className="loader" />
				) : (
					<>
						<section className={Style.responseLeft}>
							<AskedQuestion
								data={data}
								isLoading={isQuestionLoading}
								question={question}
								color="primary"
							/>
						</section>
						<section className={Style.responseCenter}>
							<div className={Style.title}>
								<p>
									<span>You </span>
									sorted your cards this way
								</p>
							</div>
							<ReviewCards
								selectCard={card => handleCardSelection(card)}
								cards={cards}
								mobileMediaQuery={mobileMediaQuery}
							/>
							<Button className={Style.backButton} onClick={handleBackButton}>
								Back
							</Button>
						</section>
						<section className={Style.responseRight}>
							{selectedCard && selectedCard.comment && !mobileMediaQuery && (
								<div className={Style.commentContainer}>
									<span>Your Comment</span>
									<div
										className={`${Style.comment}  ${selectedCard.impact &&
											(selectedCard.impact === 1
												? Style.positive
												: Style.negative)}`}
									>
										<h4>{selectedCard.name}</h4>
										<p>{selectedCard.comment}</p>
									</div>
									<div className={Style.profile}>
										<img src={auth.avatar || img} alt="User profile" />
										<p>Me</p>
									</div>
								</div>
							)}
						</section>
					</>
				)}
			</div>
		</>
	);
};

export default ReviewMyResponse;
