import { useEffect, useState } from 'react';
import axios from 'axios';
import { getAuthToken } from '../helpers/authHelper';

export default () => {
	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const host = process.env.REACT_APP_API_HOST ?? '';

	const fetchUsers = async () => {
		setIsLoading(true);

		try {
			const idToken = await getAuthToken(true);

			if (!idToken) {
				return;
			}
			const usersListResponse = await axios.get(`${host}/api/users`, {
				headers: {
					Authorization: `Bearer ${idToken}`
				}
			});
			const { data: usersListData } = usersListResponse;

			setUsers(usersListData.users);
		} catch (err) {
			setUsers([]);
			setError(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	return [users, isLoading, error];
};
