/* eslint-disable consistent-return */
import React, { useContext, useState } from 'react';
import {
	Button,
	Container,
	FormControl,
	InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as firebase from 'firebase/app';
import * as Style from './NewQuestion.module.scss';
import UserSearch from '../../components/UserSearch/UserSearch';
import { GlobalContext } from '../../context/Context';
import StyledTextField from './NewQuestion.style';
import messageSent from '../../assets/images/message_sent.svg';
import AlertComponent from '../../components/Alert/Alert';
import { emailFieldError, emailRegEx } from '../../constants/constants';

const useStyles = makeStyles({
	textAreaformControl: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	inputAdornmentPosition: {
		alignSelf: 'flex-end',
		margin: '1rem'
	},
	alertRoot: {
		margin: '1rem',
		backgroundColor: '#ff4d4d'
	},
	alertAction: {
		display: 'none'
	}
});

const NewQuestion = () => {
	const classes = useStyles();
	const MAX_CHAR_COUNT = 300;
	const header = document.querySelector('[data-testid="header"]');
	const [auth] = useContext(GlobalContext);
	const [showPostedMessage, setShowPostedMessage] = useState(false);
	const [charCount, setCharCount] = useState(0);
	const [textAreaValue, setTextAreaValue] = useState('');
	const [errors, setErrors] = useState({
		selectionError: '',
		questionError: ''
	});
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [text, setText] = useState('');

	const db = firebase.firestore();
	const collectionRef = db.collection('questions');

	const handleChange = e => {
		const userEntry = e.target.value.trim();
		const charsTotalLength = e.target.value.length;
		if (userEntry.length) {
			setErrors(prevState => ({
				...prevState,
				questionError: ''
			}));
		}
		setText(userEntry);
		if (charsTotalLength <= MAX_CHAR_COUNT) {
			setCharCount(charsTotalLength);
			setTextAreaValue(e.target.value);
		} else if (charsTotalLength > MAX_CHAR_COUNT) {
			const trimmed = e.target.value.substr(0, MAX_CHAR_COUNT);
			setTextAreaValue(trimmed);
			setCharCount(trimmed.length);
		}
	};

	const handleSubmit = async e => {
		e.preventDefault();

		if (!text) {
			setErrors(prevState => ({
				...prevState,
				questionError: 'This field cannot be left blank.'
			}));
		} else {
			setErrors(prevState => ({
				...prevState,
				questionError: ''
			}));
		}
		const wordsCount = text.split(' ');
		if (wordsCount.length < 3) {
			setErrors(prevState => ({
				...prevState,
				questionError:
					'Please make sure that your question is at least 3 words.'
			}));
		}

		const isAnonymous =
			!Array.isArray(selectedUsers) && selectedUsers?.isAnonymous;
		if (isAnonymous) {
			if (!emailRegEx.test(selectedUsers?.email)) {
				setErrors(prevState => ({
					...prevState,
					selectionError: emailFieldError
				}));
			}
		}

		if (!isAnonymous && !selectedUsers.length) {
			setErrors(prevState => ({
				...prevState,
				selectionError: 'You must select at least one recipient.'
			}));
		} else {
			setErrors(prevState => ({
				...prevState,
				selectionError: ''
			}));
		}
		let recipients = [];

		if (isAnonymous) {
			recipients = [];
		} else {
			recipients = selectedUsers.map(user => user.id);
		}

		const { uid, firstName, lastName, subscriptionId } = auth;

		if ((recipients.length || isAnonymous) && text && wordsCount.length >= 3) {
			const newQuestion = {
				text,
				date: new Date(),
				creater_id: uid,
				creater_first_name: firstName,
				creater_last_name: lastName,
				recipients,
				responded: {},
				subscription_id: subscriptionId
			};
			let docRef;

			try {
				docRef = await collectionRef.add(newQuestion);
				setShowPostedMessage(true);
				header.scrollIntoView();
			} catch (err) {
				return err; // error here
			}

			try {
				if (isAnonymous) {
					await axios.post('/send_email_new_question_anonymous', {
						dest: selectedUsers.email,
						from: auth.uid,
						href:
							docRef && docRef.id
								? `${window.origin}/responses/${docRef.id}/direct-response`
								: `${window.origin}`
					});
				} else {
					await axios.post('/send_email_new_question', {
						dest: recipients,
						from: auth.uid,
						href:
							docRef && docRef.id
								? `${window.origin}/responses/${docRef.id}/post-response`
								: `${window.origin}`
					});
				}
			} catch (err) {
				return err; // error here
			}
		}
		window.scrollTo(0, 0);
		return true;
	};
	return (
		<Container className={Style.container} disableGutters={false} maxWidth="md">
			{!showPostedMessage && (
				<>
					<h1 className={Style.title}>Having a question to ask?</h1>
					{errors.selectionError && (
						<AlertComponent
							classes={{ root: classes.alertRoot, action: classes.alertAction }}
							className="errorContainer"
							variant="filled"
							message={errors.selectionError}
							severityIndicator="error"
						/>
					)}
					<UserSearch
						selectedUsers={selectedUsers}
						setSelectedUsers={setSelectedUsers}
						setErrors={setErrors}
					/>
					<form className={Style.question__form}>
						<FormControl classes={{ root: classes.textAreaformControl }}>
							{errors.questionError && (
								<AlertComponent
									classes={{
										root: classes.alertRoot,
										action: classes.alertAction
									}}
									className="errorContainer"
									variant="filled"
									message={errors.questionError}
									severityIndicator="error"
								/>
							)}
							<StyledTextField
								onChange={handleChange}
								value={textAreaValue}
								fullWidth
								required
								InputProps={{
									endAdornment: (
										<InputAdornment
											className={classes.inputAdornmentPosition}
											position="end"
										>
											{charCount}/{MAX_CHAR_COUNT}
										</InputAdornment>
									)
								}}
								rows={5}
								variant="outlined"
								multiline
								placeholder="Type your question here..."
							/>
						</FormControl>
						<FormControl className={Style.question_form_button_container}>
							<Button
								className={Style.question__form__button}
								variant="contained"
								color="primary"
								href="#questions"
								endIcon={<SendIcon />}
								onClick={e => handleSubmit(e)}
							>
								Send
							</Button>
						</FormControl>
					</form>
				</>
			)}
			{showPostedMessage && (
				<div className={Style.success_message}>
					<div className={Style.success_message_iconContainer}>
						<img src={messageSent} alt="message sent" />
					</div>
					<h1 className={Style.title}>
						Your question was submitted successfully!
					</h1>
					<p className={Style.success_message_body}>
						Now you can check your questions list to monitor the responses you
						will have regarding the question you had asked.
					</p>
					<Link className={Style.success_message_button} to="/questions">
						Check your questions
					</Link>
				</div>
			)}
		</Container>
	);
};
export default NewQuestion;
