import { useContext, useEffect, useState } from 'react';
import * as firebase from 'firebase';
import { CardsContext } from '../context/Context';

const useFetchAnswer = (initialValue, questionId, answerId) => {
	// declare state
	const [didCancel, setDidCancel] = useState(false);
	const [data, setData] = useState(initialValue);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [cards] = useContext(CardsContext);
	const db = firebase.firestore();
	const cardsObject = {};

	if (cards && cards.length) {
		// eslint-disable-next-line no-restricted-syntax
		for (const { id, ...elseCardData } of cards) {
			cardsObject[id] = { ...elseCardData };
		}
	}

	const fetchData = async () => {
		setIsError(false);

		let answerSnapshot;
		let resultObject;

		try {
			answerSnapshot = await db
				.collection('questions')
				.doc(questionId)
				.collection('answers')
				.doc(answerId)
				.get();
		} catch (er) {
			if (!didCancel) {
				setIsError(true);
				setError(er.message);
			}
		}

		/* eslint-disable camelcase */

		if (answerSnapshot) {
			const {
				user,
				date,
				first_name,
				last_name,
				cards: answerCards,
				...elseAnswerData
			} = answerSnapshot.data();

			resultObject = {
				id: answerSnapshot.id,
				date: date.toDate(),
				firstName: first_name,
				lastName: last_name,
				cards: answerCards.map(({ card, ...elseAnswerCardData }) => ({
					id: card,
					...cardsObject[card],
					...elseAnswerCardData
				})),
				...elseAnswerData
			};
		}
		/* eslint-enable camelcase */

		if (!didCancel) {
			setData(resultObject);
		}

		setIsLoading(false);
	};

	// TODO we need to convert this useFetch to factory pattern with type to determine which collection will be retrieved
	useEffect(() => {
		setDidCancel(false);
		// handle fetching data request
		if (cards.length) fetchData();
		// clean up function
		return () => {
			setDidCancel(true);
		};
	}, [cards]); // eslint-disable-line react-hooks/exhaustive-deps

	return [data, isLoading, error, isError];
};

export default useFetchAnswer;
